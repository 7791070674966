@import "../../assets/scss/_variables";
@import "../../assets/scss/mixins";

.drawer-nav {
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  @media (max-width: $breakpointMdMax) {
    height: 100%;
  }

  overflow: hidden;
  //-ms-overflow-style: none; // IE 10+
  //scrollbar-width: none; // Firefox
}
//.drawer-nav::-webkit-scrollbar {
//  width: 0;
//  height: 0;
//  display: none;
//}

.drawer__container {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  margin-bottom: auto;

  overflow: scroll;
  //overflow-x: hidden;
  -ms-overflow-style: none; // IE 10+
  scrollbar-width: none; // Firefox
}

.drawer__container::-webkit-scrollbar {
  display: none; // Safari and Chrome
  width: 0;
  height: 0;
}

.drawer__header {
  display: flex;
  justify-content: flex-start;
  align-items: center;

  margin-left: 8px;
  margin-right: 8px;
  padding: 8px 12px 8px 4px;

  border-bottom: 1px solid rgba($colorWhite, 0.2);

  @media (min-width: $breakpointMd) {
    display: none;
  }
}

.drawer-menu__wrapper {
  margin-top: 8px;
  //padding-left: 26px;
  //padding-right: 32px;

  margin-bottom: auto;

  @media (min-width: $breakpointMd) {
    //padding-left: 20px;
    //padding-right: 18px;
  }
}

.drawer__header-close {
}

.drawer__header-logo {
  padding-top: 6px;
  margin-left: 22px;
  margin-right: auto;
}

.drawer__header-logo {
}

.drawer__header-link {
  display: flex;
  justify-content: center;
  align-items: center;

  font-size: 14px;
  line-height: 16px;

  color: $colorWhite;
  text-decoration: none;
  opacity: 0.6;
}
.drawer__header-logout-text {
  margin-right: 8px;
}

.drawer-menu,
.submenu__drawer {
  @include reset-ul();
}

.drawer-menu__item__2 {
  //display: flex;
  //justify-content: center;
  //align-items: center;

  width: 100%;
  min-height: 56px;
  height: 100%;

  margin-bottom: 8px;

  &.active {
    background-color: rgba(0, 0, 0, 0.1);
  }
}
.drawer-menu__item {
  margin-bottom: 24px;
}

.drawer-menu__link-text {
  flex-grow: 1;
  text-align: left;
}

.drawer-menu__link-icon {
  margin-right: 12px;
  width: 24px;
  height: 24px;
}

.drawer-menu__item-submenu-arrow {
}

button.drawer-menu__link {
  @include reset-button();
}

.middle__drawer button.drawer-menu__link {
  padding: 0;
  padding-right: 18px;
}

.middle__drawer {
  @include reset-ul();
  margin-left: 50px;
  padding-bottom: 24px;
}

.middle__drawer__item {
  margin-top: 16px;
}

button.drawer-menu__link.middle__drawer__link {
  margin-top: 16px;
  min-height: 0;

  font-size: 14px;
  line-height: 21px;
}

button.drawer-menu__link,
.drawer-menu__link {
  height: 100%;
  min-height: 56px;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;

  padding-left: 26px;
  padding-right: 32px;

  @media (min-width: $breakpointMd) {
    padding-left: 20px;
    padding-right: 18px;
  }

  font-size: 15px;
  line-height: 16px;
  letter-spacing: 0.5px;

  color: $colorWhite;

  opacity: 0.6;
  text-decoration: none;
}

.submenu__drawer__link {
  font-size: 14px;
  line-height: 16px;
  letter-spacing: 0.5px;

  color: $colorWhite;

  opacity: 0.6;
  text-decoration: none;

  &.submenu__drawer__link--middle {
    font-size: 14px;
    line-height: 21px;
    font-weight: 500;
  }
}

.drawer-menu__link:hover,
.submenu__drawer__link:hover {
  opacity: 0.9;
}

.middle__drawer .drawer-menu__link.active {
  background-color: transparent;
}

.drawer-menu__item__2.current {
  position: relative;

  &::before {
    content: "";
    position: absolute;
    left: 0;
    display: block;
    width: 3px;
    height: 56px;

    background: rgba(255, 255, 255, 0.85);
    border-radius: 0 2px 2px 0;
  }
}

.drawer-menu__link.active {
  background-color: rgba(0, 0, 0, 0.1);
}

.drawer-menu__link.active,
.submenu__drawer__link.active {
  opacity: 1;
}

.submenu__drawer.closed {
  display: none;
}

.submenu__drawer {
  margin-top: 16px;

  padding-left: 8px;

  border-left: 1px solid #6d6b91;
}

.submenu__drawer__item {
  position: relative;
  margin-bottom: 16px;
}

.submenu__drawer__link.active::before {
  position: absolute;
  top: 0;
  left: -10px;
  content: "";
  display: block;
  width: 2px;
  height: 100%;
  background-color: $colorWhite;
}

.drawer__footer {
}

.drawer__footer-link {
  @media only screen and (max-width: $breakpointMdMax) {
    width: 100%;
    height: 100%;

    display: flex;
    padding: 16px 24px;
    margin-top: auto;
    align-items: center;

    & img {
      margin-bottom: 5px;
      opacity: 0.6;
    }
  }

  display: none;
}
.drawer__footer-wrapper {
  @media only screen and (max-width: $breakpointMdMax) {
    width: 100%;
    //height: 100%;

    display: flex;
    padding: 16px 24px;
    margin-top: auto;
    align-items: center;

    cursor: pointer;

    & img {
      margin-bottom: 5px;
      opacity: 0.6;
    }
  }

  display: none;
}

.drawer__footer-icon {
  height: 24px;
}

.drawer__footer-name {
  flex-grow: 1;
  margin-left: 16px;

  font-size: 15px;
  line-height: 16px;
  letter-spacing: 0.5px;

  color: rgba($colorWhite, 0.6);
}
