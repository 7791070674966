@import "../../assets/scss/_variables";

.app-bar {
  position: fixed;
  z-index: 1250;
  bottom: 0;

  height: 56px;
  width: 100vw;
  padding: 8px 16px;

  background-color: $colorWhite;

  border-top: 1px solid $colorBackgroundSecondary;

  &.app-bar--with-handle {
    height: 57px;
    border-top: none;

    background-color: transparent;

    background-image: url(/assets/img/mobile-bottom-main.png),
      url(/assets/img/mobile-bottom-menu.svg);
    background-repeat: no-repeat, no-repeat;
    background-position: left 0 center, right 0 center;
    background-size: calc(100% - 150px) 57px, contain;

    //background-image: url(/assets/img/mobile-bottom-menu.svg);
    //background-repeat: no-repeat;
    //background-position: right 0 center;
  }
}

.app-bar__wrapper {
  height: 100%;
}

.app-bar__button-container {
  position: absolute;
  z-index: 1100;
  left: 0;
  right: 16px;
  bottom: 28px;

  border-radius: 50%;

  @media (max-width: $breakpointMdMax) {
    bottom: 70px;
  }

  & .button-save {
    left: unset;
    right: 16px;
    transform: none;
  }
}

.app-bar__links {
  display: flex;
  justify-content: space-around;
  align-items: center;

  height: 100%;
}

.app-bar__link {
  display: flex;
  flex-direction: column;
  align-items: center;

  font-size: 12px;
  line-height: 18px;
  text-align: center;
  letter-spacing: 0.5px;

  color: rgba($colorTextTetriary, 0.6);

  /* Text Tetriary */
  opacity: 0.9;

  text-decoration: none;

  & svg path {
    fill: #c4c4c4;
  }

  &.active {
    color: $colorBrand;
  }

  &.active svg path {
    fill: $colorBrand;
  }

  &.app-bar__link--with-handle {
    margin-right: 80px;
  }

  .app-bar--with-handle &:last-child {
    margin-right: 20%;
  }
}

.test {
  .app-bar-bottom:before {
    position: absolute;
    display: block;
    content: "";
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: #6102ed;

    clip-path: polygon(
      0 0,
      calc(100% - 38px) 0,
      calc(100% - 37.487665px) 0.628287px,
      calc(100% - 36.975331px) 1.256382px,
      calc(100% - 36.462997px) 1.884094px,
      calc(100% - 35.912306px) 2.511233px,
      calc(100% - 35.863009px) 3.137607px,
      calc(100% - 35.802788px) 3.763025px,
      calc(100% - 35.731661px) 4.387296px,
      calc(100% - 35.64965px) 5.010232px,
      calc(100% - 35.55678px) 5.631641px,
      calc(100% - 35.453079px) 6.251334px,
      calc(100% - 35.338579px) 6.869124px,
      calc(100% - 35.213314px) 7.484821px,
      calc(100% - 35.077322px) 8.098238px,
      calc(100% - 34.930646px) 8.709188px,
      calc(100% - 34.77333px) 9.317486px,
      calc(100% - 34.605421px) 9.922945px,
      calc(100% - 34.426971px) 10.525381px,
      calc(100% - 34.238035px) 11.124612px,
      calc(100% - 34.038669px) 11.720454px,
      calc(100% - 33.828934px) 12.312725px,
      calc(100% - 33.608895px) 12.901246px,
      calc(100% - 33.378619px) 13.485837px,
      calc(100% - 33.138175px) 14.066321px,
      calc(100% - 32.887636px) 14.642519px,
      calc(100% - 32.62708px) 15.214257px,
      calc(100% - 32.356586px) 15.781361px,
      calc(100% - 32.076235px) 16.343658px,
      calc(100% - 31.786113px) 16.900976px,
      calc(100% - 31.486309px) 17.453146px,
      calc(100% - 31.176915px) 18px,
      calc(100% - 30.858023px) 18.541371px,
      calc(100% - 30.529731px) 19.077094px,
      calc(100% - 30.19214px) 19.607005px,
      calc(100% - 29.845353px) 20.130945px,
      calc(100% - 29.489474px) 20.648752px,
      calc(100% - 29.124612px) 21.160269px,
      calc(100% - 28.750878px) 21.665341px,
      calc(100% - 28.368387px) 22.163813px,
      calc(100% - 27.977255px) 22.655534px,
      calc(100% - 27.5776px) 23.140354px,
      calc(100% - 27.169545px) 23.618125px,
      calc(100% - 26.753214px) 24.088702px,
      calc(100% - 26.328733px) 24.551941px,
      calc(100% - 25.896233px) 25.007701px,
      calc(100% - 25.455844px) 25.455844px,
      calc(100% - 25.007701px) 25.896233px,
      calc(100% - 24.551941px) 26.328733px,
      calc(100% - 24.088702px) 26.753214px,
      calc(100% - 23.618125px) 27.169545px,
      calc(100% - 23.140354px) 27.5776px,
      calc(100% - 22.655534px) 27.977255px,
      calc(100% - 22.163813px) 28.368387px,
      calc(100% - 21.665341px) 28.750878px,
      calc(100% - 21.160269px) 29.124612px,
      calc(100% - 20.648752px) 29.489474px,
      calc(100% - 20.130945px) 29.845353px,
      calc(100% - 19.607005px) 30.19214px,
      calc(100% - 19.077094px) 30.529731px,
      calc(100% - 18.541371px) 30.858023px,
      calc(100% - 18px) 31.176915px,
      calc(100% - 17.453146px) 31.486309px,
      calc(100% - 16.900976px) 31.786113px,
      calc(100% - 16.343658px) 32.076235px,
      calc(100% - 15.781361px) 32.356586px,
      calc(100% - 15.214257px) 32.62708px,
      calc(100% - 14.642519px) 32.887636px,
      calc(100% - 14.066321px) 33.138175px,
      calc(100% - 13.485837px) 33.378619px,
      calc(100% - 12.901246px) 33.608895px,
      calc(100% - 12.312725px) 33.828934px,
      calc(100% - 11.720454px) 34.038669px,
      calc(100% - 11.124612px) 34.238035px,
      calc(100% - 10.525381px) 34.426971px,
      calc(100% - 9.922945px) 34.605421px,
      calc(100% - 9.317486px) 34.77333px,
      calc(100% - 8.709188px) 34.930646px,
      calc(100% - 8.098238px) 35.077322px,
      calc(100% - 7.484821px) 35.213314px,
      calc(100% - 6.869124px) 35.338579px,
      calc(100% - 6.251334px) 35.453079px,
      calc(100% - 5.631641px) 35.55678px,
      calc(100% - 5.010232px) 35.64965px,
      calc(100% - 4.387296px) 35.731661px,
      calc(100% - 3.763025px) 35.802788px,
      calc(100% - 3.137607px) 35.863009px,
      calc(100% - 2.511233px) 35.912306px,
      calc(100% - 1.884094px) 35.950663px,
      calc(100% - 1.256382px) 35.97807px,
      calc(100% - 0.628287px) 35.994517px,
      100% 36px,
      calc(100% + 0.628287px) 35.994517px,
      calc(100% + 1.256382px) 35.97807px,
      calc(100% + 1.884094px) 35.950663px,
      calc(100% + 2.511233px) 35.912306px,
      calc(100% + 3.137607px) 35.863009px,
      calc(100% + 3.763025px) 35.802788px,
      calc(100% + 4.387296px) 35.731661px,
      calc(100% + 5.010232px) 35.64965px,
      calc(100% + 5.631641px) 35.55678px,
      calc(100% + 6.251334px) 35.453079px,
      calc(100% + 6.869124px) 35.338579px,
      calc(100% + 7.484821px) 35.213314px,
      calc(100% + 8.098238px) 35.077322px,
      calc(100% + 8.709188px) 34.930646px,
      calc(100% + 9.317486px) 34.77333px,
      calc(100% + 9.922945px) 34.605421px,
      calc(100% + 10.525381px) 34.426971px,
      calc(100% + 11.124612px) 34.238035px,
      calc(100% + 11.720454px) 34.038669px,
      calc(100% + 12.312725px) 33.828934px,
      calc(100% + 12.901246px) 33.608895px,
      calc(100% + 13.485837px) 33.378619px,
      calc(100% + 14.066321px) 33.138175px,
      calc(100% + 14.642519px) 32.887636px,
      calc(100% + 15.214257px) 32.62708px,
      calc(100% + 15.781361px) 32.356586px,
      calc(100% + 16.343658px) 32.076235px,
      calc(100% + 16.900976px) 31.786113px,
      calc(100% + 17.453146px) 31.486309px,
      calc(100% + 18px) 31.176915px,
      calc(100% + 18.541371px) 30.858023px,
      calc(100% + 19.077094px) 30.529731px,
      calc(100% + 19.607005px) 30.19214px,
      calc(100% + 20.130945px) 29.845353px,
      calc(100% + 20.648752px) 29.489474px,
      calc(100% + 21.160269px) 29.124612px,
      calc(100% + 21.665341px) 28.750878px,
      calc(100% + 22.163813px) 28.368387px,
      calc(100% + 22.655534px) 27.977255px,
      calc(100% + 23.140354px) 27.5776px,
      calc(100% + 23.618125px) 27.169545px,
      calc(100% + 24.088702px) 26.753214px,
      calc(100% + 24.551941px) 26.328733px,
      calc(100% + 25.007701px) 25.896233px,
      calc(100% + 25.455844px) 25.455844px,
      calc(100% + 25.896233px) 25.007701px,
      calc(100% + 26.328733px) 24.551941px,
      calc(100% + 26.753214px) 24.088702px,
      calc(100% + 27.169545px) 23.618125px,
      calc(100% + 27.5776px) 23.140354px,
      calc(100% + 27.977255px) 22.655534px,
      calc(100% + 28.368387px) 22.163813px,
      calc(100% + 28.750878px) 21.665341px,
      calc(100% + 29.124612px) 21.160269px,
      calc(100% + 29.489474px) 20.648752px,
      calc(100% + 29.845353px) 20.130945px,
      calc(100% + 30.19214px) 19.607005px,
      calc(100% + 30.529731px) 19.077094px,
      calc(100% + 30.858023px) 18.541371px,
      calc(100% + 31.176915px) 18px,
      calc(100% + 31.486309px) 17.453146px,
      calc(100% + 31.786113px) 16.900976px,
      calc(100% + 32.076235px) 16.343658px,
      calc(100% + 32.356586px) 15.781361px,
      calc(100% + 32.62708px) 15.214257px,
      calc(100% + 32.887636px) 14.642519px,
      calc(100% + 33.138175px) 14.066321px,
      calc(100% + 33.378619px) 13.485837px,
      calc(100% + 33.608895px) 12.901246px,
      calc(100% + 33.828934px) 12.312725px,
      calc(100% + 34.038669px) 11.720454px,
      calc(100% + 34.238035px) 11.124612px,
      calc(100% + 34.426971px) 10.525381px,
      calc(100% + 34.605421px) 9.922945px,
      calc(100% + 34.77333px) 9.317486px,
      calc(100% + 34.930646px) 8.709188px,
      calc(100% + 35.077322px) 8.098238px,
      calc(100% + 35.213314px) 7.484821px,
      calc(100% + 35.338579px) 6.869124px,
      calc(100% + 35.453079px) 6.251334px,
      calc(100% + 35.55678px) 5.631641px,
      calc(100% + 35.64965px) 5.010232px,
      calc(100% + 35.731661px) 4.387296px,
      calc(100% + 35.802788px) 3.763025px,
      calc(100% + 35.863009px) 3.137607px,
      calc(100% + 35.912306px) 2.511233px,
      calc(100% + 36.462997px) 1.884094px,
      calc(100% + 36.975331px) 1.256382px,
      calc(100% + 37.487665px) 0.628287px,
      calc(100% + 38px) 0,
      100% 0,
      100% 100%,
      0 100%
    );
  }
}
