@import "../../assets/scss/_variables";
@import "../../assets/scss/mixins";

$breakpointSummary: 1450px;

.summary {
  @include box-shadow-1();
  position: fixed;
  z-index: 100;

  bottom: 56px;
  left: 260px;

  background-color: $colorWhite;
  border-radius: 4px;

  transition: all 0.2s ease-in;

  &.summary--dialog {
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;

    margin-top: auto;
    width: 100%;

    &:not(.summary--opened) {
      background-color: transparent;
    }
  }

  .drawer-closed & {
    left: -1px;
  }

  .drawer-opened & {
    left: 219px;
    transition: all 0.2s ease-out;
  }

  &.summary--opened {
    @include box-shadow-3();

    .drawer-closed & {
      left: 40px;
    }

    .drawer-opened & {
      left: 260px;
    }
  }

  @media (max-width: $breakpointSummary) {
    &:not(.summary--dialog) {
      bottom: 0;
      left: 50%;
      transform: translateX(-50%);

      .drawer-closed & {
        left: 50%;
      }

      .drawer-opened & {
        transition: all 0.2s ease-out;
        left: 50%;
      }

      &.summary--opened {
        .drawer-closed & {
          left: 50%;
        }

        .drawer-opened & {
          left: 50%;
        }
      }
    }
  }
}

.summary__wrapper {
  display: flex;
  justify-content: flex-start;

  @media (max-width: $breakpointSummary) {
    flex-direction: column;

    .summary--dialog & {
      flex-direction: row;
    }
  }
}
.summary__content {
  display: flex;
  max-width: 1px;
  max-height: 1px;

  height: 1px;
  flex-wrap: wrap;

  overflow: hidden;

  transition: all 0.3s ease-in;

  .summary--opened & {
    max-height: 300px;
    max-width: 2000px;
    height: auto;

    transition: all 0.3s ease-out;
  }

  .summary--dialog & {
    margin-left: -30px;
  }
}

.summary__content-item {
  display: flex;

  transition: all 0.4s ease-in;
  opacity: 0;

  padding: 0px;
  margin-right: 0px;
  overflow: hidden;
  max-width: 0;
  max-height: 0;

  .summary--opened & {
    margin-right: 16px;
    padding: 16px;

    transition: all 0.4s ease-out;
    max-width: 500px;
    max-height: 100px;
    opacity: 1;

    @media (max-width: $breakpointSummary) {
      margin-right: 32px;
    }
  }
  .summary--dialog.summary--opened & {
    width: 200px;
    margin-left: 30px;
    margin-right: 0;
  }

  @media (max-width: $breakpointSummary) {
  }
}

.summary__content-icon {
  margin-right: 10px;
}

.summary__content-label {
  @include title-with-label();

  max-width: 160px;

  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;

  @media (max-width: $breakpointSummary) {
    //text-overflow: ellipsis;
    //white-space: nowrap;
    max-width: 90px;
    //overflow: hidden;
  }
}

.summary__content-count {
  @include title-with-count();
}

.summary__total {
  min-height: 100%;

  .summary--dialog:not(.summary--opened) & {
    margin-left: 30px;
  }
  .summary--dialog.summary--opened & {
    margin-left: auto;
  }
}

.summary__total-wrapper {
  height: 100%;
  display: flex;
  border-radius: 0 4px 4px 0;
  background-color: $colorBackgroundYellow;

  cursor: pointer;

  @media (max-width: $breakpointSummary) {
    :not(.summary--dialog) {
      justify-content: center;
    }
  }
}

.summary__total-title {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 12px;
}

.summary__total-label {
  @include title-with-label();

  color: $colorUiYellow;
}

.summary__total-count {
  @include title-with-count();

  font-size: 20px;
  line-height: 26px;

  color: $colorUiYellow;
}

.summary__total-arrow {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 12px 4px;

  min-height: 66px;

  border-left: 1px solid rgba($colorUiYellow, 0.2);

  & svg {
    transition: 0.5s;
    transform: scaleX(-1);

    .summary--opened & {
      transition: 0.5s;
      transform: scaleX(1);
    }
  }

  @media (max-width: $breakpointSummary) {
    :not(.summary--dialog) {
      & svg {
        transform: rotate(90deg);

        .summary--opened & {
          transition: 0.5s;
          transform: rotate(-90deg);
        }
      }
    }
  }
}
