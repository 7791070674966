@import "../../../assets/scss/variables";
@import "../../../assets/scss/mixins";

.side-menu {
  height: calc(100vh - 81px);
  min-width: 344px;
  max-width: 344px;
  margin-top: 16px;
  margin-right: 16px;

  &.side-menu--new {
    min-width: unset;
    max-width: unset;
    margin-top: 0;
    margin-right: 0;

    height: 100%;
  }

  &--dialog {
    max-width: 95vw;
    height: auto;
    margin: -24px;

    .button-side-item {
      z-index: 2;
    }

    .side {
      &-menu {
        &__main {
          min-height: 0;
          box-shadow: none;

          &-wrapper {
            padding-top: 0;
          }
        }
      }

      &-item {
        &__wrapper {
          padding: 0;
        }

        &__top {
          padding: 16px;
        }

        &__content {
          margin-top: 0;
          margin-bottom: 30px;
          padding-bottom: 50px;

          section {
            min-width: 300px;
            max-width: 300px;
            margin: 24px;
          }

          button {
            position: absolute;
            bottom: -10px;
            right: 24px;
            width: auto;
          }
        }
      }
    }
  }

  @media (max-width: $breakpointMdMax) {
    display: none;
    width: 100%;
    min-height: 100vh;
    min-width: unset;
    max-width: unset;
    margin: 0;

    &.side-menu--open {
      display: block;
    }

    &.side-menu--secondary {
      background-color: $colorBackgroundSecondary; // TODO maybe need bgc to all panel
    }
  }
}

.side-menu__top {
}

.side-menu__main {
  @include box-shadow-1();
  position: relative;
  display: flex;
  flex-direction: column;
  min-height: calc(100vh - 140px);

  // IE 11 hack
  @media all and (-ms-high-contrast: none) {
    *::-ms-backdrop,
    & {
      justify-content: space-between;
    }
  }

  background-color: #fff;
  border-radius: 4px;

  &.side-menu__main--secondary {
    background-color: $colorBackgroundSecondary; // TODO maybe need bgc to all panel
  }
}

.side-menu__main-wrapper {
  padding-top: 20px;
  flex-grow: 1;

  @media (max-width: $breakpointMdMax) {
    padding-top: 14px;
  }
}

.side-menu__title {
  @include h2();

  margin-bottom: 22px;
  margin-left: 24px;
  margin-right: 24px;

  &.side-menu__title--mobile {
    margin-left: 56px;
  }
}

.side-menu__bottom {
  border-radius: 0 0 4px 4px;
  background-color: $colorBackgroundSecondary;

  @media all and (-ms-high-contrast: none) {
    *::-ms-backdrop,
    & {
      margin-top: auto;
      max-width: 100%;
      align-self: flex-end;
      justify-self: flex-end;
    }
  }
}

.side-menu__caption {
  margin: 0 auto;
  text-align: center;
}

.side-menu__caption-button {
  @include reset-button();

  margin: 18px auto;

  text-align: center;
  font-family: $font;
  font-size: 14px;
  line-height: 16px;
  letter-spacing: 0.5px;
  text-decoration-line: underline;

  color: $colorUi;
}

.side-menu__back {
  z-index: 1;
  position: absolute;
  top: 16px;
  left: 16px;

  &--edit {
    top: 30px;
    left: 16px;
  }
}
