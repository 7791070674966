@import "../../assets/scss/_variables";
@import "../../assets/scss/mixins";

.main-container {
  .stat-container {
    display: flex;
    margin-bottom: 24px;

    @media (max-width: $breakpointMdMax) {
      flex-wrap: wrap;
    }
  }

  .stat-content {
    margin-right: -16px;
    margin-left: 30px;
    width: 100%;

    @media (max-width: $breakpointMdMax) {
      margin-left: 16px;
      margin-right: 16px;
    }
  }

  .toolbar-buttons {
    position: relative;

    margin-top: 4px;
  }

  .toolbar-buttons__categories {
    @include box-shadow-3();
    //width: 193px;
    width: 250px;
    padding: 16px;
    padding-top: 4px;

    background: $colorWhite;
    border-radius: 4px;

    & > .toolbar-buttons__add {
      //position: static;
    }

    & .toolbar-buttons__add-tile.toolbar-buttons__add-tile {
      //right: -250px;
    }
  }

  .toolbar-buttons__add {
    position: relative;

    & > .toolbar-buttons__add-tile {
      position: absolute;
      z-index: 500;
      right: -276px;
      top: -24px;

      display: none;
      padding: 20px;
      width: 280px;

      &.toolbar-buttons__add-tile--categories {
        padding-left: 32px;
      }
    }

    &:hover {
      & > .toolbar-buttons__add-tile {
        display: block;
      }
    }
  }

  .dashboard-advisor__overall-button {
    @include reset-button();
    position: fixed;
    right: 0;
    top: 150px;
    z-index: 1291;

    background: #fff4e8;
    border-top-right-radius: 4px;
    border-bottom-right-radius: 4px;

    @include box-shadow-1();

    transform: scaleX(-1);

    &.dashboard-advisor__overall-button--opened {
      transform: scaleX(1);
      right: 18px;

      .summary__total-arrow {
        border-left: none;
      }
    }

    @media (max-width: 1820px) {
      top: 150px;
    }

    @media (max-width: 1650px) {
      top: 160px;
    }

    @media (max-width: 1349px) {
      top: 200px;
    }
  }

  .scrollbar-measure {
    width: 100px;
    height: 100px;
    overflow: scroll;
    position: absolute;
    top: -9999px;
  }

  .dashboard-advisor__container {
    position: fixed;
    right: 0;
    top: 0;
    width: auto;
    height: auto;
    z-index: 1200;

    @media (max-width: $breakpointMdMaxMui) {
      position: absolute;
    }
  }

  .dashboard-advisor__content {
    position: absolute;
    top: 64px;
    right: 0;
    padding: 16px 32px 16px 40px;
    background: rgba(7, 5, 29, 0.5);
    //backdrop-filter: blur(5px);

    @media (max-width: $breakpointMdMax) {
      top: 0;
    }
  }

  .dashboard__title {
  }

  .dashboard__select {
    margin-right: auto;
    margin-left: 20px;
  }

  .dashboard__select-control {
    margin: 0;
  }

  .dashboard__switch {
    &:not(:first-child) {
      margin-left: 24px;
    }

    &.dashboard__switch--right {
      margin-left: auto;
    }
  }

  .dashboard__what-if {
    margin-left: 24px;
    margin-right: auto;
  }

  .graphics {
    &__cont {
      border-radius: 4px;
      overflow: hidden;
      @include box-shadow-1();
      font-size: 0; // TODO remove after insert graphic
      line-height: 0;
      width: 100%;
      background: #ffffff;

      img {
        // TODO remove after insert graphic
        width: 100%;
        height: auto;
        min-width: 100%;
        min-height: 100%;
      }
    }
  }

  .features {
    @include box-shadow-1();
    border-radius: 4px;
    overflow: hidden;
    padding: 16px 0 0 16px;
    background: #ffffff;

    &__list {
      @include reset-ul();
      text-transform: capitalize;
      font-size: 18px;
      line-height: 1;
      font-weight: 700;
      margin-bottom: 8px;
      position: relative;
      padding-left: 65px;

      &--green {
        border-top: 1px solid $colorTextGreen;
        color: $colorTextGreen;

        .features__item {
          background: linear-gradient(
            270deg,
            #dbf3df 0%,
            rgba(76, 196, 97, 0) 100%
          );
        }
      }

      &--orange {
        border-top: 1px solid $colorUiYellow;
        color: $colorUiYellow;

        .features__item {
          background: linear-gradient(
            270deg,
            #ffebce 0%,
            rgba(255, 157, 10, 0) 100%
          );
        }
      }

      &--red {
        border-top: 1px solid $colorTextRed;
        color: $colorTextRed;

        .features__item {
          background: linear-gradient(
            270deg,
            #ffd8d6 0%,
            rgba(255, 59, 48, 0) 100%
          );
        }
      }
    }

    &__label {
      position: absolute;
      left: 0;
      top: 11px;
      text-transform: uppercase;
      color: $colorTextTetriary;
      font-size: 11px;
      letter-spacing: 1px;
      background: none;
    }

    &__item {
      padding: 20px 40px 20px 0;
      margin-bottom: 16px;
      text-align: right;
    }
  }

  .stat-summary {
    margin-left: -8px;
    display: flex;
    justify-content: space-between;
    align-items: flex-start;

    flex-wrap: wrap;

    @media (max-width: $breakpointMdMax) {
      flex-direction: column;
    }

    &__section {
      align-self: stretch;
      //flex: 37 1 auto;
      flex: 1;
      background: $colorWhite;
      box-shadow: 0 2px 4px rgba(30, 50, 90, 0.15);
      border-radius: 4px;

      margin-left: 8px;

      &--short {
        width: 342px;
      }

      @media (max-width: $breakpointMdMax) {
        margin-bottom: 16px;

        &--short {
          width: auto;
        }
      }
    }

    &__header {
      height: 64px;
      display: flex;
      justify-content: space-between;

      padding: 8px 16px;

      border-bottom: 1px solid $colorDivider;
    }

    &__title {
      font-size: 30px;
      font-weight: 700;
      line-height: 40px;
      letter-spacing: 0.5px;
      color: $colorTextPrimary;
    }

    &__label {
      font-size: 11px;
      font-weight: 700;
      line-height: 16px;
      text-transform: uppercase;
      color: $colorTextTetriary;
    }

    &__header-data {
    }

    &__data {
      font-size: 14px;
      font-weight: 700;
      line-height: 21px;
      letter-spacing: 0.5px;

      text-align: right;
      color: $colorTextPrimary;

      &--caption {
        font-size: 11px;
        line-height: 16px;
        letter-spacing: 1px;
        text-transform: uppercase;
        color: $colorBrand;
      }

      &--comparison {
        display: inline-block;
        width: 8px;
        height: 8px;
        margin-left: 4px;

        &::before {
          display: inline-block;
          content: "";

          width: 0;
          height: 0;

          margin-bottom: 2px;
          border-style: solid;
        }
      }

      &--comparison-less {
        &::before {
          border-width: 0 4px 8px 4px;
          border-color: transparent transparent $colorTextGreen transparent;
        }
      }
      &--comparison-greater {
        &::before {
          border-width: 8px 4px 0 4px;
          border-color: $colorTextRed transparent transparent transparent;
        }
      }
      &--comparison-hide {
        &::before {
          content: none;
        }
      }

      &--head {
        font-size: 18px;
        line-height: 24px;
      }

      &--green {
        color: $colorTextGreen;
      }
      &--brand {
        color: $colorBrand;
      }
      &--yellow {
        color: $colorUiYellow;
      }
      &--ui {
        color: $colorUi;
      }

      &--item {
        margin-bottom: 4px;
      }
    }

    &__item {
      padding: 16px 16px 32px 16px;
      &--tax {
        padding-left: 0;
      }

      &:not(:last-child) {
        border-bottom: 1px solid $colorDivider;
      }

      &-content {
        display: flex;
        justify-content: space-between;

        &--std,
        &--wif {
          flex: 1;
        }
      }
    }

    &__tax-item {
      padding: 8px;
      padding-left: 38px;
      position: relative;

      &--active {
        background: linear-gradient(
          270deg,
          rgba(196, 196, 196, 0) 0%,
          rgba(196, 196, 196, 0.2) 100%
        );
      }

      &::before {
        content: "";
        position: absolute;
        left: 16px;
        top: 12px;
        display: block;
        width: 14px;
        height: 14px;
        border: 5px solid $colorBrand;
        border-radius: 50%;
      }

      &--green {
        &::before {
          border-color: $colorTextGreen;
        }
      }
      &--light-yellow {
        &::before {
          border-color: #ffcf3c;
        }
      }
      &--yellow {
        &::before {
          border-color: $colorUiYellow;
        }
      }
      &--blue {
        &::before {
          border-color: #bad3ff;
        }
      }
      &--brand {
        &::before {
          border-color: $colorBrand;
        }
      }
    }
    &__chart-wif {
      position: absolute;
      top: 36px;
      left: 36px;
      background: #dbdef7;

      width: 80px;
      height: 80px;
      border-radius: 50%;

      font-size: 11px;
      font-weight: 500;
      line-height: 16px;
      display: flex;
      justify-content: center;
      align-items: center;
      text-align: right;
      letter-spacing: 1px;
      text-transform: uppercase;

      color: $colorBrand;
    }
  }

  .statistic {
    flex-wrap: wrap;

    &__grid-item {
      flex-grow: 1;

      &--double {
        flex-grow: 2;

        @media (max-width: $breakpointMdMax) {
          min-width: 100%;
        }
      }
    }

    &__item {
      @include box-shadow-1();
      border-radius: 4px;
      overflow: hidden;
      width: 100%;
      background: $colorWhite;
      text-align: left;

      &--low {
        @include box-shadow-1();
        background-color: $colorBackgroundSecondary;

        border-radius: 4px;
      }

      &-header {
        display: flex;
        flex-wrap: wrap;
        padding: 8px 16px;

        &.statistic__item-header-icon {
          //height: 37px;
          position: relative;
          padding-left: 56px;
          border-bottom: 1px solid $colorDivider;
        }

        &.statistic__item-header-short {
          border-bottom: none;
        }
      }

      .statistic__item-header-icon .statistic__item-icon {
        position: absolute;
        left: 16px;
      }

      &-icon {
        margin-right: 10px;

        &--cursor {
          cursor: pointer;
        }
      }

      &-row {
        width: 100%;
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;

        & .statistic__item-title {
          width: auto;
        }
      }

      &-title {
        //margin-right: auto;
        width: 100%;
        font-size: 11px;
        line-height: 16px;
        font-weight: 500;
        letter-spacing: 1px;
        text-transform: uppercase;

        &--if {
          margin-right: 0;
          margin-left: auto;
          text-align: right;

          color: $colorBrand;
        }
      }

      &-count {
        margin-right: 16px;
        font-weight: 700;
        font-size: 14px;
        color: $colorTextPrimary;

        &--negative {
          color: $colorTextRed;
        }

        &--negative-wif {
          color: $colorUiYellow;
        }
      }

      &-body {
        padding: 16px;

        &.statistic__item-body--taxes-short {
          @media (max-width: 1280px) {
            display: flex;
            justify-content: space-between;
            align-items: center;

            & .statistic__index {
              margin-top: 0;

              &:not(:first-child) {
                margin-left: 16px;
              }
            }
          }
        }

        &--short-tax {
          display: flex;
          justify-content: space-between;
          flex-wrap: wrap;
        }

        &--group {
          display: flex;
          flex-direction: row;
          flex-wrap: wrap;
          padding: 0;
          height: 100%;

          & > * {
            width: 50%;
          }
        }

        &--caption {
          width: 100%;
          padding-top: 16px;
          padding-bottom: 16px;

          align-self: center;

          font-size: 14px;
          line-height: 16px;
          display: flex;
          align-items: center;
          text-align: center;
          letter-spacing: 0.5px;

          color: $colorUi;

          cursor: pointer;

          & > span {
            display: block;
            margin: 0 auto;

            & > span {
              margin-right: 8px;
            }
          }

          @media (min-width: 1280px) {
            height: 100%;
            margin-top: -13%;
          }

          @media (min-width: 1920px) {
            height: 100%;
            margin-top: -70px;
          }
        }
      }
    }

    .statistic__item-body--short-tax .statistic__index {
      margin-top: 0;
      &:not(:last-child) {
        margin-right: 24px;
      }
    }

    .statistic__item-body--short-tax .statistic__index-title--if {
      margin-top: 16px;
    }

    &__index {
      margin-top: 8px;

      &:first-child {
        margin-top: 0;
      }

      .statistic__item-body--short-tax & {
        margin-right: 8px;
      }

      &-container {
        display: flex;
        justify-content: space-between;
        flex-wrap: wrap;
      }

      &-title {
        color: $colorTextTetriary;
        font-size: 11px;
        font-weight: 500;
        line-height: 16px;
        text-transform: uppercase;

        &--action {
          cursor: pointer;

          & > span {
            border-bottom: 1px $colorTextTetriary dashed;
          }
        }

        &--if {
          color: $colorBrand;
        }
      }

      &-count {
        color: $colorTextPrimary;
        font-weight: 700;
        margin-right: 16px;

        &--if {
          margin-right: 0;
          color: $colorBrand;
        }

        @media (max-width: 1500px) {
          font-size: 12px;
        }

        @media (max-width: 1366px) {
          width: 100%;
        }

        @media (max-width: 1280px) {
          font-size: 14px;
        }
      }

      &-progressbar {
        width: auto;
        position: relative;
        padding-right: 16px;
      }

      &-degree {
        border-radius: 4px;
        height: 4px;
        display: inline-block;

        &--purple {
          background: $colorBrand;
        }

        &--orange {
          background: $colorUiYellow;
        }

        &--green {
          background: $colorTextGreen;
        }
      }

      &-percent {
        font-size: 12px;
        color: $colorTextPrimary;
        display: inline-block;
        margin-left: 8px;
        vertical-align: middle;
      }
    }
  }

  .recommendations {
    margin-top: 30px;

    &__form {
      &-title {
        margin-right: 32px;
        margin-bottom: 22px;
        padding-bottom: 6px;
        font-size: 11px;
        font-weight: 500;
        line-height: 16px;

        color: $colorBrand;

        border-bottom: 1px solid rgba($colorBrand, 0.2);
      }

      &-item {
        margin-right: 32px;
        margin-bottom: 27px;

        font-size: 14px;
        line-height: 21px;
        color: $colorTextPrimary;

        label + div {
          margin-top: 0;
        }

        &.recommendations__form-item--advisor {
          margin-bottom: 20px;
        }
      }

      &-item--primary {
        margin-bottom: 16px;

        font-size: 14px;
        font-weight: 700;
        line-height: 21px;
        color: $colorTextPrimary;
      }
    }

    &__title {
      font-size: 20px;
      line-height: 26px;
      color: $colorTextPrimary;
      text-align: left;
    }

    &__wrap {
      padding: 6px 32px 20px 32px;
      border-radius: 4px;
      background: #e8ebf7;
      text-align: left;
      margin-bottom: 56px;

      @media (max-width: $breakpointMdMax) {
        padding: 6px 16px 20px 16px;
      }

      &--advisor {
        background: none;
      }
    }

    &__subtitle {
      color: $colorBrand;
      font-size: 14px;
      font-weight: 500;
      text-transform: uppercase;
    }

    &__collapse {
      padding-left: 60px;
      counter-reset: list 0;
      position: relative;

      @media (max-width: $breakpointMdMax) {
        padding-left: 46px;
      }

      &:before {
        content: "";
        position: absolute;
        width: 1px;
        height: calc(100% - 40px);
        top: 20px;
        left: 20px;
        background: $colorBrand;

        @media (max-width: $breakpointMdMax) {
          left: 17px;
        }
      }

      &--advisor {
        padding-left: 40px;

        &:before {
          content: none;
          width: 0;
          height: 0;
        }
      }
    }

    &__item {
      margin: 0 0 8px 0;
      @include box-shadow-1();
      position: relative;
      border-radius: 4px;

      &:before {
        content: none;
      }

      &:after {
        content: "" !important;
        position: absolute;
        z-index: 3;
        display: block;
        width: 50px;
        height: 50px;
        background: #e8ebf7;
        top: 11px;
        left: -78px;

        @media (max-width: $breakpointMdMax) {
          width: 40px;
          height: 40px;
          left: -50px;
        }
      }

      &-title {
        position: static;

        @media (max-width: $breakpointMdMax) {
          padding: 0 16px;
        }

        > div {
          margin: 20px 0;

          @media (max-width: $breakpointMdMax) {
            margin: 10px 0;
          }
        }

        p {
          font-weight: 500;
          font-size: 18px;
          color: $colorTextPrimary;
          max-width: 900px;
          padding: 0;

          @media (max-width: $breakpointMdMax) {
            font-size: 14px;
          }
        }

        &:before {
          content: " " !important;
          position: absolute;
          display: block;
          width: 7px;
          height: 20px;
          top: 25px;
          background: url("assets/img/arrow-small-left.svg") no-repeat center;
          left: -7px;
          z-index: 5;

          @media (max-width: $breakpointMdMax) {
            top: 18px;
          }
        }

        &:after {
          counter-increment: list;
          content: counter(list);
          position: absolute;
          display: block;
          width: 40px;
          height: 40px;
          background: $colorBrand;
          color: #ffffff;
          font-size: 20px;
          font-weight: 700;
          line-height: 40px;
          text-align: center;
          border-radius: 50%;
          overflow: hidden;
          top: 16px;
          left: -60px;
          z-index: 10;

          @media (max-width: $breakpointMdMax) {
            width: 32px;
            height: 32px;
            line-height: 32px;
            font-size: 18px;
            left: -45px;
            top: 12px;
          }
        }
      }

      &-left {
        font-size: 16px;
        line-height: 24px;

        //&.recommendations__item-left--li {
        //  max-width: 55%;
        //  flex-basis: 55%;
        //}
      }

      &-right {
        //&.recommendations__item-right--li {
        //  max-width: 45%;
        //  flex-basis: 45%;
        //}

        > &--wrapper {
          padding: 15px 25px 25px 25px;
          background: #e8ebf7;
          border-radius: 4px;
          overflow: hidden;
        }

        &.recommendations__item-right--advisor {
          padding: 24px 32px 32px 32px;
          background: rgba($colorUi, 0.1);
          border-radius: 4px;

          > div {
            padding: 0;
            //box-sizing: border-box;

            &:not(:last-child) {
              //width: calc(100% - 32px);
              //margin-right: 20px;
            }
          }
        }

        &--right {
          margin-left: 8px;
          margin-right: -8px;
        }

        &--top {
          margin-bottom: 8px;
        }
      }

      &:last-child {
        &.recommendations__item:after {
          height: 100%;
        }
      }
    }

    button {
      margin-right: 8px;
    }

    &-checklist {
      background: white;
      box-shadow: 0px 2px 4px rgba(30, 50, 90, 0.15);
      border-radius: 4px;

      .recommendations {
        &__wrap {
          padding: 0;
          background: none;
        }

        &__collapse {
          padding-left: 0;

          &:before {
            display: none;
          }
        }

        &__item {
          box-shadow: none;

          &:before,
          &:after {
            display: none;
          }

          &-title {
            display: none;

            &:before,
            &:after {
              display: none;
            }
          }

          &-right {
            &.recommendations__item-right--advisor {
              border: 1px solid rgba(139, 150, 160, 0.5);
              background: none;
            }
          }
        }

        &__title {
          margin: 0;
        }

        &-head {
          padding: 22px 32px 0;
          background: linear-gradient(
            180deg,
            #f0f3fd 0%,
            rgba(255, 255, 255, 0) 100%
          );
        }

        &-toggle-list {
          display: flex;
          margin-top: 20px;
          padding: 0;
          background: white;

          &__item {
            position: relative;
            display: inline-flex;
            align-items: center;
            justify-content: center;
            max-width: 150px;
            padding: 13px 24px;
            font-weight: bold;
            font-size: 14px;
            line-height: 21px;
            text-align: center;
            letter-spacing: 0.5px;
            color: $colorTextPrimary;
            cursor: pointer;

            &:after {
              content: "";
              position: absolute;
              right: 0;
              top: 8px;
              bottom: 8px;
              width: 1px;
              background: #e8eaec;
            }

            &:last-child {
              &:after {
                display: none;
              }
            }

            &:first-child {
              &:before {
                display: none !important;
              }
            }

            &--active {
              background: #e1e7fc;
              border-radius: 4px;

              &:before {
                content: "";
                position: absolute;
                left: -1px;
                top: 8px;
                bottom: 8px;
                width: 1px;
                background: white;
              }

              &:after {
                display: none;
              }
            }

            @media (max-width: 1500px) {
              padding: 6px 12px;
              font-size: 12px;
              line-height: 16px;
            }

            @media (max-width: 1300px) {
              padding: 5px 8px;
            }

            @media (max-width: 1100px) {
              width: 20%;
              margin: 4px 0;
            }
          }

          @media (max-width: 1100px) {
            flex-wrap: wrap;
          }

          @media (max-width: 767px) {
            display: none;
          }
        }

        @media (max-width: 767px) {
          &__item {
            box-shadow: 0 2px 4px rgba(30, 50, 90, 0.15);

            &:before,
            &:after {
              display: inline-block;
            }

            &-title {
              display: block;

              &:before,
              &:after {
                display: inline-block;
              }
            }
          }

          &-head {
            padding: 0;
            background: none;
          }
        }
      }

      @media (max-width: 767px) {
        box-shadow: none;
      }
    }

    @media (max-width: $breakpointMdMax) {
      &__wrap {
        margin-top: -14px;
        padding: 0;
        background: none;
      }

      &__form {
        &-title {
          margin-right: 30px;
          margin-bottom: 15px;

          &-subtitle {
            margin-bottom: 0;
            font-size: 12px;
            line-height: 18px;
            color: $colorFieldLabel;
            border: none;
          }
        }

        &-item {
          margin-bottom: 2px;

          &-wrap {
            margin-bottom: 19px;
          }

          &.recommendations__form-item--advisor {
            margin-bottom: 2px;
            margin-right: 0;
          }
        }
      }

      &__title {
        font-weight: 500;
        font-size: 16px;
        line-height: 24px;
      }

      &__collapse {
        padding-left: 0;

        &:before {
          display: none;
        }
      }

      &__item {
        overflow: hidden;

        &-title {
          min-height: 40px !important;
          padding: 7px 20px 7px 45px;
          border-top: 1px solid #e8eaec;

          &[aria-expanded="true"] {
            border-bottom: none;
            border-left: none;
            border-right: none;
          }

          p {
            font-size: 16px;
            line-height: 24px;
          }

          > div {
            margin: 0;
          }

          &:before {
            display: none;
          }

          &:after {
            left: -9px;
            top: -3px;
            width: 46px;
            height: 46px;
            padding-left: 5px;
            font-size: 20px;
            line-height: 46px;
          }
        }

        &-left {
          font-size: 14px;
          line-height: 21px;

          &--li {
            p {
              margin: -2px 0 23px;
            }
          }
        }

        &-right {
          &-wrap {
            margin: 0 -25px 8px;
            max-width: 120%;
            flex-basis: 120%;
          }

          &.recommendations__item-right--advisor {
            padding: 17px 24px 10px 24px;
          }
        }

        &-buttons {
          display: flex;
          align-items: center;
          justify-content: space-between;
          flex-wrap: wrap;
          padding-top: 0 !important;

          button {
            flex-shrink: 0;
            width: 47%;
            min-width: 120px;

            &:nth-child(2) {
              margin-right: 0;
            }

            &:nth-child(3) {
              width: 100%;
              margin-top: 8px;
            }
          }
        }
      }
    }
  }
}

.statistic__grid-item-group--short .statistic__item-body--group {
  height: auto;
}

.statistic__item-body--group .statistic__item-title .statistic__item-count {
  @media (max-width: 1500px) {
    font-size: 12px;
  }

  @media (max-width: 1266px) {
    width: 100%;
  }
}

.statistic__item-body--group .statistic__item-icon {
  display: block;
}

.statistic__item-body--group .statistic__item {
  box-shadow: none;
  border-radius: 0;

  outline: 1px solid $colorDivider;
}

.dashboard__chart-main-container {
  width: 100%;

  @media (max-width: $breakpointMdMax) {
    margin-top: 0;
  }
}

.dashboard__chart-title {
  margin-top: 24px;

  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;

  font-size: 18px;
  line-height: 24px;
  font-weight: 500;
  color: $colorTextSecondary;

  &--left,
  &--right {
    display: flex;
    align-items: center;
  }

  &--current-timeline {
    min-width: 28px;
    font-size: 16px;
    line-height: 24px;
    text-align: center;
    letter-spacing: 0.5px;

    color: $colorTextSecondary;
  }
}

.dashboard__chart-axis {
  position: relative;
  //width: calc(100% - 13px);
  //margin: -10px -15px 5px 28px;
  width: calc(100% - 16px);
  margin: -10px -15px 5px 30px;
  min-height: 32px;
  //margin-right: -20px;
  //margin-left: 10px;

  display: flex;
  justify-content: space-between;

  &--with-track {
    background-image: url(/assets/img/events/timeLineTrackGrey.png);
    background-repeat: repeat-x;
    background-size: 100% 1px;
  }

  &--alone {
    margin: 32px 0 0 0;
  }
}

.dashboard__chart-axis-marks {
  margin-top: 16px;
  display: flex;
  justify-content: space-around;
  align-items: center;
}

.dashboard__chart-axis-circle {
  @include reset-button();
  display: block;
  width: 4px;
  height: 4px;

  background-color: $colorTextTetriary;
  mix-blend-mode: normal;

  box-sizing: border-box;
  border-radius: 50%;

  //border: 1.5px solid transparent;
  border: 5px solid red;

  &--event {
    width: 7px;
    height: 7px;
    margin-top: -1.5px;

    background-color: $colorBrand;
  }
}

.dashboard__chart-axis-button {
  margin-top: -20px;
  min-width: 0;
}

.recharts-legend-wrapper {
  display: flex;
  justify-content: flex-end;
  height: 55px;

  @media (max-width: $breakpointMdMax) {
    justify-content: space-between;
  }
}

.recharts-legend-list {
  margin: 0;
  padding: 0;

  position: relative;
  right: 0;

  display: flex;
  margin-top: 16px;

  justify-content: flex-start;
  align-items: flex-start;
  flex-wrap: wrap;
  flex-direction: column;

  @media (max-width: $breakpointMdMax) {
    margin-top: 0;
    margin-left: 0;
    padding-left: 20px;
  }
}

.recharts-legend-item {
  display: flex;
  justify-content: center;
  align-items: center;
  //margin-right: 32px;
  margin-bottom: 12px;
  opacity: 0.5;
  cursor: pointer;

  &--active {
    opacity: 1;

    .dashboard__legend-square {
      position: relative;

      &:before,
      &:after {
        content: "";
        position: absolute;
        top: 6px;
        left: 4px;
        width: 2px;
        height: 6px;
        background: $colorWhite;
        border-radius: 2px;
        transform: rotate(-45deg);
      }

      &:after {
        transform: rotate(45deg);
        top: 2px;
        left: 9px;
        height: 10px;
      }
    }
  }

  @media (max-width: $breakpointMdMax) {
    margin-right: 16px;
    margin-bottom: 16px;
  }
}

.dashboard__legend-square {
  display: inline-block;
  width: 16px;
  height: 16px;
  margin-right: 10px;
  border-radius: 2px;
}

.recharts-legend-item-text {
  font-size: 14px;
  line-height: 21px;
  color: $colorTextPrimary;

  //text-transform: uppercase;
}
.recharts-tooltip__add-item {
  margin-left: 6px;
  position: relative;

  & > .icon-container > svg:first-child {
    position: relative;
    left: 2px;
  }
  & > .icon-container > svg:last-child {
    position: relative;
    right: 2px;
  }

  &:first-child {
    margin-left: 0;
  }
}

.recharts-tooltip__add-item--icon {
  display: block;
  width: 12px;
  height: 12px;

  background-repeat: no-repeat;
  background-image: url(/assets/img/icon/plusDashboard.png);
  position: absolute;
  left: 6px;
  top: 6px;
}

.recharts-curve.recharts-tooltip-cursor {
  z-index: 100;
}
.recharts-layer.recharts-active-dot {
  z-index: 200;
}

.recharts-layer.recharts-cartesian-axis-tick {
  font-size: 11px;
  line-height: 16px;

  color: $colorTextTetriary;
  text-transform: uppercase;
}

.wizard-grid {
  &--left {
    padding-right: 48px;
  }

  &--right {
    padding-left: 48px;
  }
}

.dashboard__test {
  margin-top: 16px;
}

.dashboard__test-container {
  margin-top: 8px;

  margin-bottom: 16px;
  margin-left: -10px;
  display: flex;
  flex-wrap: wrap;

  & > * {
    margin-left: 10px;
    margin-bottom: 8px;
    cursor: pointer;
  }
}

.dashboard__panel-categories {
  display: inline-flex;
  width: 100%;
  height: 40px;
  overflow: hidden;

  &__title {
    display: none;
    padding: 12px 41px;
    font-weight: 500;
    font-size: 14px;
    line-height: 16px;
    letter-spacing: 0.5px;
    text-transform: uppercase;
    color: $colorWhite;
    background: $colorBrandSecondary;
  }

  .dash-categories__full {
    .dashboard__panel-categories__title {
      display: inline-block;
    }
  }

  @media (max-width: 1780px) {
    &__title {
      padding: 12px 11px;
    }
  }

  @media (max-width: 1650px) {
    height: auto;
    min-height: 40px;
    padding-right: 0;

    &__title {
      padding: 12px 11px;
      border-bottom-right-radius: 4px;
    }
  }

  @media (max-width: 1460px) {
    min-height: 48px;

    &__title {
      display: inline-block;
      padding: 16px 23px;
    }

    .dash-categories__full {
      .dashboard__panel-categories__title {
        display: none;
      }
    }
  }

  @media (max-width: 1200px) {

  }

  @media (max-width: 767px) {
    padding-right: 0;

    &__title {
      position: absolute;
      top: 0;
      left: 0;
      padding: 4px 0;
      width: 100%;
      background: none;
      font-size: 11px;
      line-height: 16px;
    }
  }
}

.dash-categories {
  display: flex;
  justify-content: flex-start;
  height: 100%;
  width: 100%;
  padding: 0;

  &__simple &__item {
    font-size: 18px;
    line-height: 24px;
    margin-bottom: 24px;
    text-transform: none;
  }

  &__sub,
  &__simple {
    display: flex;
    flex-direction: column;
    flex: 1;
    align-items: baseline;
  }

  &__item {
    @include reset-button();
    padding: 0 1px 0 10px;
    margin-bottom: 0;
    display: inline-flex;
    font-weight: 500;
    font-size: 12px;
    line-height: 16px;
    align-items: center;
    letter-spacing: 0.5px;
    color: $colorWhite;
    text-transform: uppercase;
    text-overflow: ellipsis;
    word-spacing: normal;
    white-space: nowrap;

    & > svg {
      margin-right: 8px;
    }

    &-title {
      margin-bottom: 12px;

      font-size: 18px;
      line-height: 24px;
      letter-spacing: 0.5px;

      color: $colorTextTetriary;
    }

    //&:focus {
    //  outline: none;
    //}

    //&:focus,
    &:hover {
      opacity: 0.7;
    }

    &:active {
      opacity: 0.5;
    }

    span {
      display: none;

      &:first-of-type{
        display: inline-block;
      }
    }
  }

  &__full {
    display: inline-flex;
    justify-content: space-between;
    width: 100%;
  }

  &-arrow {
    display: none;
  }

  @media (max-width: 1780px) {
    &__item {
      padding: 0 1px 0 5px;
    }
  }

  @media (max-width: 1650px) {
    flex-wrap: wrap;
    justify-content: flex-start;
    padding: 0;
    padding-right:20px;

    &__item {
      padding: 0 0 0 10px;
      height:40px;

      span {
        &:first-of-type{
          display: none;
        }

        &:last-of-type{
          display: inline-block;
        }
      }
    }
  }

  @media (max-width: 1460px) {
    position: relative;
    flex-wrap: nowrap;
    padding-right:20px;

    &__full {
      overflow-x: auto;

      &::-webkit-scrollbar {
        height: 0 !important
      }

      overflow: -moz-scrollbars-none;
      -ms-overflow-style: none;
    }

    &-arrow {
      @include reset-button();
      display: inline-block;
      position: absolute;
      top:0;
      left:114px;
      height:100%;
      width: 95px;
      background: linear-gradient(90deg, #6885EE 30.73%, rgba(104, 133, 238, 0.736842) 67.71%, rgba(104, 133, 238, 0) 100%);
      z-index: 2;

      &:before,
      &:after{
        content: '';
        position: absolute;
        top:20px;
        left:8px;
        width:10px;
        height:2px;
        background: #FFFFFF;
        transform: rotate(-45deg);
      }

      &:after {
        top:26px;
        transform: rotate(45deg);
      }

      &--right {
        left:auto;
        right:0;
        -webkit-transform: rotate(180deg);
        -moz-transform: rotate(180deg);
        -ms-transform: rotate(180deg);
        -o-transform: rotate(180deg);
        transform: rotate(180deg);
      }
    }

    &__item {
      position: relative;
      padding: 0 10px 0 8px;
      height: 48px;

      &:after {
        content: '';
        position: absolute;
        top:12px;
        bottom:12px;
        right:0;
        width:1px;
        background: #E8EAEC;
        opacity: 0.3;
      }

      &:last-child {
        &:after {
          display: none;
        }
      }


      span {
        display: none;

        &:last-child {
          display: none;
        }

        &:first-of-type{
          display: inline-block;
        }
      }
    }
  }

  @media (max-width: 767px) {
    position: relative;
    justify-content: center;
    flex-wrap: nowrap;
    padding: 16px 9px 4px;

    &__full {
      display: flex;
    }

    &__top,
    &__bottom {
      display: none;
      width: auto;
    }

    &__item {
      height: 40px;
      margin: 0 7px;
      padding: 0;

      &:after {
        display: none;
      }

      span {
        display: none!important;
      }

      > svg {
        margin-right:0;
      }
    }

    &-arrow {
      display: none;
    }
  }
}

.dashboard__panel-what-if {
  position: relative;
  margin-left: 35px;
  flex-shrink: 0;
  outline: none !important;

  &-button {
    @include reset-button();
    display: inline-flex;
    align-items: center;
    height: 100%;
    line-height: 40px;
    padding: 4px 10px 4px 16px;
    background: $colorBackgroundSecondary;
    text-transform: uppercase;
    outline: none !important;

    &:disabled {
      cursor: default;

      span {
        color: $colorTextTetriary;
      }

      & svg > path {
        fill: $colorTextTetriary;
      }
    }
    span {
      color: $colorUi;
      font-weight: 500;
      font-size: 14px;
      line-height: 16px;
      letter-spacing: 0.5px;
    }

    .summary__total-arrow {
      min-height: 0;
      height: 16px;
      padding: 0;
      margin-left: 8px;
      border: none;
      transform: rotate(90deg);
    }

    &--opened {
      .summary__total-arrow {
        transform: rotate(-90deg);
      }
    }
  }

  &__content {
    display: inline-flex;
    align-items: center;
    position: absolute;
    top: 102%;
    right: -40px;
    padding: 2px 50px 2px 15px;
    width: auto;
    background: white;
    box-shadow: 0px 0px 12px rgba(46, 63, 82, 0.25);
    border: 1px solid rgba(104, 133, 238, 0.5);
    border-radius: 4px;
    border-top-left-radius: 0;
    border-top-right-radius: 0;
    border-top: none;
    border-right: none;

    &-link {
      @include reset-button();
      margin-bottom: 0;
      font-size: 14px;
      font-weight: 500;
      line-height: 16px;
      letter-spacing: 0.5px;
      text-decoration-line: underline;

      color: $colorUi;

      //&:focus,
      &:hover {
        outline: none;
        opacity: 0.7;
      }
      &:active {
        opacity: 0.5;
      }
    }

    &__addlink {
      padding: 0;
      font-size: 16px;
      line-height: 23px;
      font-style: normal;
      font-weight: normal;
      letter-spacing: 0.5px;
      color: #6885ee;
      text-decoration: none;
      white-space: nowrap;
    }

    @media (max-width: 1820px) {
      right: 0;
      padding: 2px 10px 2px 15px;
    }
  }

  &__asset {
    margin-right: 15px;
  }

  &__left {
    display: none;
  }

  @media (max-width: 1780px) {
    margin-left: 15px;
  }

  @media (max-width: 1650px) {
    position: absolute;
    top: 100%;
    right: 0;
    min-width: 0;
    max-width: 100%;
    border-bottom-left-radius: 4px;
    overflow: hidden;
    border-top: none;
    border-color: $colorBrand;
    box-shadow: 0px 2px 4px rgba(30, 50, 90, 0.15);

    &--reverse {
      flex-direction: row-reverse;
    }

    &--opened {
      overflow: visible;
      border-bottom-left-radius: 0;
    }

    &__left {
      display: none;
    }

    &-button {
      display: inline-flex;
      flex-direction: row-reverse;
      height: 38px;
      right: calc(100% + 1px);
      padding: 4px 16px 4px 12px;
      border-color: $colorUi;
      flex-shrink: 0;

      .summary__total-arrow {
        transform: rotate(180deg);
        margin-right: 8px;
        margin-left: 0;
      }

      &--opened {
        .summary__total-arrow {
          transform: rotate(0deg);
        }
      }
    }

    &__content {
      position: absolute;
      right: 100%;
      right: calc(100% + 1px);
      top: 0;
      flex-wrap: nowrap;
      padding: 0 15px 0 9px;
      border-radius: 0;
      border-bottom-left-radius: 4px;
      border-left: 1px solid rgba(104, 133, 238, 0.5);
      border-bottom: 1px solid rgba(104, 133, 238, 0.5);
      box-shadow: 0px 2px 4px rgba(30, 50, 90, 0.15);

      .dashboard__panel-what-if__content-link {
        display: inline-block;
        margin-left: 7px;
        line-height: 38px;
      }

      &__addlink {
        padding: 0;
        font-size: 14px;
        line-height: 38px;
        white-space: nowrap;
      }
    }

    &__asset {
      display: inline-flex;
      align-items: center;
      line-height: 38px;

      svg {
        width: 24px;
        height: 24px;
      }
    }
  }

  @media (max-width: 767px) {
    display: flex;
    border-bottom-left-radius: 4px;
    overflow: hidden;
    margin:0;

    &--opened {
      right: auto;
      left: 0;
      width: 100%;
      border-top: none;
      border-left: none;
      border-bottom-color: $colorBrand;
      border-radius:0;

      .dashboard__panel-what-if-button {
        padding: 4px 4px 4px 11px;
        border:none;
        border-radius:0;

        span {
          display: none;
        }
      }
    }

    &--reverse {
      flex-direction: row;
      border-top: none;
      border-left: none;
      border-radius:0;
    }

    &__content {
      position: static;
      width: 100%;
      border:none;
      border-radius:0;

      .dashboard__panel-what-if__content-link {
        margin-left: auto;
      }

      &__addlink {
        display: inline-flex;
        align-items: center;
        height: 38px;
        padding: 0;
        font-size: 14px;
        line-height: 18px;
        white-space: normal;
      }
    }
  }
}

.dashboard__panel-what-if,
.dashboard__panel-vert {
  //margin-left: 16px;
}

.panel-vert {
  @include box-shadow-1();
  display: flex;
  justify-content: flex-start;
  margin-bottom: 8px;
  border-radius: 4px;

  &__title {
    z-index: 1;
    padding-top: 20px;
    width: 54px;
    display: flex;
    justify-content: flex-start;
    align-items: center;

    transform: rotate(180deg);
    writing-mode: vertical-lr;

    font-size: 14px;
    font-weight: 500;
    line-height: 16px;
    letter-spacing: 0.5px;
    text-transform: uppercase;

    color: $colorUi;

    background-color: $colorBackgroundSecondary;
    border-radius: 0 4px 4px 0;

    & > svg {
      margin-bottom: 8px;
    }

    &:not(.panel-vert__title--what-if) > svg {
      transform: rotate(90deg);
    }
  }

  &__content {
    flex: 1;
    background-color: $colorWhite;
    border-radius: 4px;

    &--what-if {
      position: relative;
      min-height: 156px;
      padding: 16px 24px;
      padding-bottom: 40px;
      //display: flex;
      //flex-wrap: wrap;
      margin-left: -16px;

      & > div {
        margin-left: 16px;
        height: 32px;
        display: inline-block;
      }

      @media (max-width: $breakpointMdMax) {
        width: 100%;
      }
    }
  }

  &__what-if-button {
    @include reset-button();

    position: absolute;
    bottom: 0;
    left: 50%;
    transform: translate(-50%, 0);

    margin-bottom: 16px;

    font-size: 14px;
    font-weight: 500;
    line-height: 16px;
    letter-spacing: 0.5px;
    text-decoration-line: underline;

    color: $colorUi;

    //&:focus,
    &:hover {
      outline: none;
      opacity: 0.7;
    }
    &:active {
      opacity: 0.5;
    }
  }
}

.toolbar-stat {
  &__tabs {
    @include reset-ul();

    font-size: 14px;
    line-height: 16px;
    letter-spacing: 0.5px;

    color: $colorTextPrimary;
  }

  &__tab {
    &-button {
      @include reset-button();
      padding: 12px 0;
      margin-left: -16px;
      padding-left: 16px;
      width: calc(100% + 32px);

      text-align: left;
      border: 1px solid transparent;

      @media (min-width: $breakpointMd) {
        border-right: none;
      }

      //&:focus,
      &:hover:not(&--active) {
        outline: none;
        opacity: 0.8;
      }

      &--active {
        position: relative;
        z-index: 100;
        //margin-left: -16px;
        //padding-left: 16px;
        //width: calc(100% + 32px);
        cursor: default;

        background: #f0f3fd;
        border-color: rgba(30, 50, 90, 0.15);

        &::before {
          content: "";
          position: absolute;
          left: 0;
          top: 0;

          display: block;
          width: 4px;
          height: 100%;

          border-radius: 0 4px 4px 0;

          background-color: $colorBrand;
        }

        &::after {
          content: "";
          position: absolute;
          z-index: 10;
          right: -15px;
          top: 5px;
          display: block;
          width: 30px;
          height: 30px;

          border: 1px solid rgba(30, 50, 90, 0.15);
          border-left: none;
          border-bottom: none;

          background: #f0f3fd;
          //box-shadow: 0px 2px 4px rgba(30, 50, 90, 0.15);
          transform: rotate(45deg);
        }

        @media (max-width: $breakpointMdMax) {
          &::after {
            display: none;
          }
        }
      }
    }
  }
}

.toolbar-button__category {
  display: flex;
  align-items: center;
  width: 100%;

  & > svg {
    margin-right: 8px;
  }

  & > span + svg {
    margin-left: auto;
    padding-left: 8px;
  }
}

.home-top-tiles__app-bar {
  position: fixed;
  z-index: 1270;
  top: 0;
  left: 260px;

  padding: 12px 8px;
  display: flex;
  justify-content: flex-start;
  align-items: center;

  max-width: 338px;
  height: 65px;

  border-left: 1px solid $colorDivider;
  border-right: 1px solid $colorDivider;

  & .home-top-tiles__value {
    margin-left: auto;

    font-size: 18px;
    line-height: 24px;
    font-weight: 500;
    color: $colorBrand;
  }

  &--icon {
    width: 32px;
    margin-right: 12px;
  }

  &--label {
    font-size: 14px;
    line-height: 21px;
    color: $colorTextSecondary;
  }
}

.home-top-tiles {
  display: flex;
  justify-content: space-between;
  position: sticky;
  top: 0;
  left: 0;
  width: auto;
  width: calc(100% + 80px);
  max-width: 100vw;
  z-index: 1100;
  margin-top: -14px;
  margin-bottom: 24px;
  margin-left: -40px;
  margin-right: -40px;
  padding-right: 40px;
  background: $colorBrand;
  @include box-shadow-1();

  &--full {
    max-width: 100vw;
  }

  @media (max-width: 1820px) {
    padding-right: 0;
  }

  @media (max-width: 1650px) {
    margin-bottom: 16px;
    box-shadow: 0px 2px 4px rgba(30, 50, 90, 0.15);
  }

  @media (max-width: 1460px) {
    max-width: calc(100vw - 220px);

    &--full {
      max-width: 100vw;
    }
  }

  @media (max-width: 1439px) {
    width: calc(100% + 48px);
    margin-left: -24px;
    margin-right: -24px;
  }

  @media (max-width: 1349px) {
    margin-bottom: 56px;
  }

  @media (max-width: 1279px) {
    padding-top: 8px;
  }

  @media (max-width: $breakpointMdMax) {
    flex-direction: column;
    margin-left: -16px;
    margin-right: -16px;
    margin-bottom: 69px;
    padding-top:0;
    max-width: 100vw;
  }

  & > .hhcard {
    margin-left: 16px;
    margin-right: 0;
    //flex: 4 1 auto;
    flex: 4;
  }

  & .hhcard__body {
    padding: 16px 24px;
  }

  &__row {
    display: flex;
    align-items: center;
    justify-content: space-between;

    font-size: 18px;
    line-height: 24px;

    &:not(:last-child) {
      margin-bottom: 20px;
    }

    @media (max-width: 1450px) {
      font-size: 16px;
      line-height: 21px;
    }
  }

  &__value {
    margin-left: 16px;
    font-weight: 500;
    color: $colorTextPrimary;
  }

  &__link {
    font-size: 14px;
    font-weight: 500;
    line-height: 16px;

    text-align: right;
    letter-spacing: 0.5px;
    text-decoration-line: underline;
    text-transform: none;

    color: $colorUi;

    &:hover {
      opacity: 0.7;
    }

    &:active {
      opacity: 0.5;
    }
  }
}
