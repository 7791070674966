@import "../../../assets/scss/variables";
@import "../../../assets/scss/mixins";

.icon-container {
  position: relative;
  width: 32px;
  height: 32px;
  display: flex;
  justify-content: center;
  align-items: center;

  border-radius: 10px;
  overflow: hidden;

  &.side-item__icon--bg_white {
    background-color: rgba($colorWhite, 0.3);
    //&:hover {
    //  background-color: rgba($colorWhite, 0.2);
    //}
  }

  &.side-item__icon--bg_no-brand {
    &:hover {
      background-color: rgba($colorBrand, 0.1);
    }
  }

  &.side-item__icon--bg_brand-full {
    background-color: rgba($colorBrand, 0.1);
    &:hover {
      background-color: $colorBrand;
    }
  }

  &.side-item__icon--bg_brand {
    background-color: rgba($colorBrand, 0.1);
  }

  &.side-item__icon--bg_ui {
    background-color: rgba($colorUi, 0.1);
  }

  &.side-item__icon--bg_red {
    background-color: rgba($colorTextRed, 0.1);
  }

  &.side-item__icon--bg_green {
    background-color: rgba($colorTextGreen, 0.1);
  }

  &.side-item__icon--bg_yellow {
    background-color: rgba($colorUiYellow, 0.1);
  }

  &.side-item__icon--bg_tetriary {
    background-color: rgba($colorTextTetriary, 0.1);
  }
  &.side-item__icon--bg_secondary {
    background-color: rgba($colorTextSecondary, 0.1);
  }

  &:hover.side-item__icon--bg_hover_brand {
    background-color: rgba($colorBrand, 0.1);
  }

  &:hover.side-item__icon--bg_hover_ui {
    background-color: rgba($colorUi, 0.1);
  }

  &:hover.side-item__icon--bg_hover_red {
    background-color: rgba($colorTextRed, 0.1);
  }

  &:hover.side-item__icon--bg_hover_green {
    background-color: rgba($colorTextGreen, 0.1);
  }

  &:hover.side-item__icon--bg_hover_yellow {
    background-color: rgba($colorUiYellow, 0.1);
  }

  &:hover.side-item__icon--bg_hover_tetriary {
    background-color: rgba($colorTextTetriary, 0.1);
  }

  & .icon-container__top-right {
    position: absolute;
    top: 0;
    right: 0;
  }

  &.icon-container--rounded {
    border-radius: 50%;
  }
}
