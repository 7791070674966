@import "../../../assets/scss/variables";
@import "../../../assets/scss/mixins";

.button-save {
  @include reset-button();

  position: fixed;
  left: 50%;
  transform: translateX(-50%);
  bottom: 28px;
  margin-left: auto;
  margin-right: auto;
  margin-top: 16px;
  padding: 8px 32px;

  font-size: 16px;
  line-height: 24px;
  text-align: center;
  letter-spacing: 0.5px;
  font-weight: 700;

  color: $colorWhite;
  background-color: $colorUiYellow;

  border-radius: 4px;

  box-shadow: 0 3px 6px rgba($colorUiYellow, 0.5);

  &.button--block {
    width: 100%;
  }

  @media (max-width: $breakpointMdMax) {
    .button--members-save & {
      bottom: 70px;
    }
  }

  &.button--round {
    border-radius: 50%;
    padding: 18px;

    width: 56px;
    height: 56px;
  }
}
