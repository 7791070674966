@import "../../assets/scss/_variables";
@import "../../assets/scss/mixins";
.drag-container {
  max-width: 980px;
  margin-bottom: 60px;

  &--goals {
    max-width: none;
    margin-bottom: 32px;
  }

  &__toolbar--config {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
}

.drag-item {
  padding: 4px 16px;

  display: flex;
  justify-content: flex-start;
  align-items: center;

  background: $colorWhite;
  border: 1px solid $colorDivider;
  box-sizing: border-box;
  border-radius: 4px;
  margin-bottom: 8px;

  &--goals {
    padding: 16px;
  }

  & > * {
    margin-right: 24px;
  }

  &__entity {
    display: flex;

    & > svg {
      align-self: center;
      min-width: 48px;
    }

    &--icon {
      margin-right: 10px;
    }
  }

  &__title {
    width: 200px;

    font-size: 11px;
    line-height: 16px;
    letter-spacing: 1px;
    color: $colorTextTetriary;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
    text-transform: uppercase;

    &--entity {
      font-size: 14px;
      line-height: 16px;
      letter-spacing: 0.5px;
      font-weight: 500;
      text-align: left;
      color: $colorTextSecondary;
    }

    &--goal-dsc {
      font-size: 14px;
      font-weight: 700;
      line-height: 21px;
      letter-spacing: 0.5px;
      text-align: left;

      color: $colorTextPrimary;
    }

    &--goals {
      margin-left: 24px;
      width: auto;
    }
  }

  &__condition {
    width: 200px;
  }

  &__order-by {
    width: 200px;
  }
  &__enabled {
    margin-left: auto;
    margin-right: 0;
    width: 80px;

    text-align: right;
  }

  &__select-all {
    width: auto;
    margin-right: 16px;
  }
}

.drag-handle {
  width: 24px;
  height: 24px;
  cursor: pointer;
}
