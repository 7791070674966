@import "../../assets/scss/_variables";

.content-main--events {
  @media (min-width: $breakpointMd) {
    & .slick-slider {
      width: calc(100% + 32px);
    }
  }
}
.main-container--dashboard {
  .slick-list {
    padding-bottom: 0;
  }
}
.slick-list {
  padding-bottom: 40px;
}

.content-main--events .slick-track {
  padding-bottom: 120px;

  .dashboard__combine & {
    padding-bottom: 0;
  }

  &::before {
    z-index: -1;
    content: "";
    position: absolute;
    top: 69px;
    display: block;
    width: 100%;
    height: 2px;

    background-image: url(/assets/img/events/timeLineTrack.png);
    background-repeat: repeat-x;

    @media (max-width: $breakpointMd) {
      top: 69px;
    }
  }

  & div {
    outline: none;
  }

  .slick-slide {
    margin-bottom: 80px;

    @media (max-width: $breakpointMdMax) {
      margin-bottom: 0;
    }

    &:first-child {
      @media (min-width: $breakpointMd) {
        padding-left: 172px;
      }
    }

    .dashboard__combine &:first-child {
      @media (min-width: $breakpointMd) {
        padding-left: 0;
      }
    }
  }
}

.events__caption-age {
  font-size: 18px;
  line-height: 30px;
  color: $colorText3;
}

.events__age {
  font-size: 18px;
  line-height: 30px;
  color: $colorTextPrimary;
  text-align: center;

  &::after {
    content: "";
    display: block;
    width: 12px;
    height: 12px;
    margin-top: 16px;
    margin-left: auto;
    margin-right: auto;

    background-color: #f6f6f8;
    mix-blend-mode: normal; /* Brand Color */
    border: 2px solid $colorBrand;
    box-sizing: border-box;
    border-radius: 50%;
  }

  &.events__age--add {
    & > span {
      display: block;
      width: 100%;
      background-color: #fafafa;
      color: transparent;
    }

    &::after {
      position: relative;
      left: 13px;

      border-color: $colorUiYellow;
    }
  }

  &.events__age--add-first {
    &::after {
      left: 19px;
    }
  }
}
.main-container--dashboard {
  .content-main--events {
    margin-left: 0;

    & .events__prev-mobile {
      left: 4px;
    }

    .slick-list {
      min-height: 230px;
    }
  }
}

.events__card-container {
  .slider-add-tile & {
    background-color: #fafafa;
    border-right: 12px solid #fafafa;
  }

  @media (max-width: $breakpointMdMax) {
    padding-bottom: 60px;
  }
}

.slick-arrow.slick-arrow {
  @media (min-width: $breakpointMd) {
    display: none !important;
  }
}

.slick-track .asset-card__wrapper {
  @media (min-width: $breakpointMd) {
    margin-left: 0;
    margin-right: 16px;
  }
}

.events__buttons {
  @media (max-width: $breakpointMdMax) {
    top: -20px;
    width: 60px;

    font-size: 18px;
    line-height: 30px; /* identical to box height, or 167% */
    text-align: right;
    letter-spacing: 0.5px;

    color: $colorUi;

    &.slick-prev:hover,
    &.slick-prev:focus,
    &.slick-next:hover,
    &.slick-next:focus {
      color: $colorUi;
      opacity: 0.8;
    }

    &.slick-prev.slick-disabled,
    &.slick-next.slick-disabled {
      color: $colorTextTetriary;
    }

    &::before {
      display: none;
    }

    &.events__prev-mobile {
      left: 16px;
    }

    &.events__next-mobile {
      right: 16px;
    }
  }
}

.slider-scroll__container {
  display: flex;
  justify-content: center;
  align-items: center;

  @media (min-width: $breakpointMd) {
    margin-top: -30px;
  }
}

.slider-add-tile {
  position: absolute;
  z-index: 500;
  left: 5px;

  @media (max-width: $breakpointMdMax) {
    width: 100%;
  }
}

.slider-arrow {
  @media (max-width: $breakpointMdMax) {
    display: none;
  }

  padding: 4px;
  cursor: pointer;

  &::before {
    content: "";
    display: block;
    width: 10px;
    height: 10px;

    border-right: 2px solid $colorUi;
    border-top: 2px solid $colorUi;
  }

  &.slider-arrow--disabled {
    opacity: 0.6;
    cursor: default;
  }
}

.slider-arrow__next {
  margin-right: 20%;
  transform: rotate(45deg);
}

.slider-arrow__prev {
  margin-left: 20%;
  transform: rotate(-135deg);
}

.slider-scroll {
  @media (max-width: $breakpointMdMax) {
    display: none;
  }

  -webkit-appearance: none;
  width: 55%;
  margin: 16px;
  background-color: transparent;
}

.slider-scroll:focus {
  outline: none;
}
.slider-scroll::-webkit-slider-runnable-track {
  width: 100%;
  height: 5px;
  cursor: pointer;
  background: rgba($colorTextTetriary, 0.2);

  border-radius: 16px;
}
.slider-scroll::-webkit-slider-thumb {
  height: 18px;
  width: 180px;
  cursor: pointer;
  -webkit-appearance: none;
  margin-top: -5px;

  @media (max-width: 1250px) {
    width: 90px;
    height: 9px;
    margin-top: -2px;

    border-radius: 16px;
    background: #6885ee;
    cursor: pointer;
  }

  background-image: url("/assets/img/events/eventScrollThumb.svg");
  background-repeat: no-repeat;
  background-position: center center;
}

.slider-scroll::-moz-range-track {
  width: 100%;
  height: 5px;
  cursor: pointer;
  background: rgba($colorTextTetriary, 0.2);
  border-radius: 16px;
}
.slider-scroll::-moz-range-thumb {
  height: 18px;
  width: 180px;
  cursor: pointer;
  margin-top: -5px;

  background-image: url("/assets/img/events/eventScrollThumb.svg");
  background-repeat: no-repeat;
  background-position: center center;
  background-color: transparent;
  border: none;

  @media (max-width: 1250px) {
    width: 90px;
    height: 9px;
    margin-top: -2px;

    border-radius: 16px;
    background: #6885ee;
    cursor: pointer;
  }
}

.slider-scroll::-ms-track {
  margin-top: 24px;
  padding-bottom: 12px;
  width: 99%;
  height: 8px;

  cursor: pointer;
  background: transparent;
  border-color: transparent;
  color: transparent;
}
.slider-scroll::-ms-fill-lower {
  background: rgba($colorTextTetriary, 0.2);
  border-radius: 16px;
}
.slider-scroll::-ms-fill-upper {
  background: rgba($colorTextTetriary, 0.2);
  border-radius: 16px;
}

.slider-scroll::-ms-thumb {
  width: 90px;
  border-radius: 16px;
  background: #6885ee;
  cursor: pointer;
  height: 18px;

  @media (max-width: 1250px) {
    width: 90px;
    height: 9px;
    margin-top: -2px;

    border-radius: 16px;
    background: #6885ee;
    cursor: pointer;
  }
}

.slider-scroll:focus::-ms-fill-lower {
  background: rgba($colorTextTetriary, 0.2);
}
.slider-scroll:focus::-ms-fill-upper {
  background: rgba($colorTextTetriary, 0.2);
}
