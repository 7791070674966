@import "./_variables";
@import "./mixins";

.main-container {
  position: relative;
  display: flex;
  flex-direction: row;
  height: 100%;

  &--dialog {
    overflow: scroll;
    overflow-x: hidden;
    overflow-y: auto;
    //height: auto;
  }
}

.main-content.main-content--dialog {
  padding: 16px 24px;
}

.main-content {
  @media (min-width: $breakpointMd) {
    flex-grow: 1;
    padding: 14px 24px 40px 24px;
  }

  @media (min-width: $breakpointLg) {
    padding: 14px 40px 40px 40px;
  }

  @media (max-width: $breakpointMdMax) {
    width: 100%;
    text-align: center;
  }

  &.main-content--not-found {
    position: relative;
    padding-bottom: 0;
    margin-top: 96px;
    //height: 500px;
    height: calc(100% - 65px);

    background-image: url(/assets/img/404_not_found.svg);
    background-repeat: no-repeat;
    background-position: center bottom -80px;
    background-size: 100% auto;
    //background-position: center bottom -200px;
    //background-position: center top 85px;
    //background-size: 100% 100%;
    //height: 100%;

    @media (max-width: $breakpointSmMax) {
      margin-top: 0;
      min-height: 380px;

      background-image: url(/assets/img/404_not_found-mobile.svg);
      background-repeat: no-repeat;
      background-position: 50% 100%;
    }
  }

  &.main-content--interests {
    max-width: 1120px;
    padding-bottom: 40px;
  }
}

.main-title {
  margin-bottom: 4px;
  font-size: 30px;
  font-weight: 700;
  line-height: 40px;
  letter-spacing: 0.5px;

  color: $colorTextPrimary;
}

.main-caption {
  margin-bottom: 20px;

  font-size: 18px;
  line-height: 30px;
  font-weight: 400;
  letter-spacing: 0.5px;

  color: $colorTextSecondary;

  opacity: 0.75;
}

.main-divider {
  display: block;
  width: 100%;
  height: 1px;

  background-color: $colorTextTetriary;
  opacity: 0.2;

  @media (max-width: $breakpointMdMax) {
    display: none;
  }
}

.main-add-form {
  @include box-shadow-1();

  padding: 16px;

  border-radius: 4px;

  background-color: $colorBackgroundSecondary;
}

.link-button {
  color: inherit;
  text-decoration: none;
  outline: none;
}

.button--fixed-bottom {
  @media (max-width: $breakpointMdMax) {
    z-index: 1000;
    position: fixed;
    left: 50%;
    bottom: 70px;

    transform: translateX(-50%);

    margin-left: auto;
    margin-right: auto;

    font-size: 16px;
    line-height: 24px;
    text-align: center;
    letter-spacing: 0.5px;
    font-weight: 700;
  }
}
