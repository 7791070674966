@import "../../assets/scss/variables";

.content-header {
  @media (max-width: $breakpointMdMax) {
    padding: 20px 24px 75px 24px;

    background: linear-gradient(180deg, #453074 0%, #564dd0 100%);

    &.content-header--dashboard {
      padding-bottom: 25px;
      background: transparent;
    }

    &.content-header--content-empty {
      padding-bottom: 25px;
    }
  }

  &.content-header--dashboard {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    flex-wrap: wrap;

    @media (max-width: $breakpointMdMax) {
      justify-content: space-between;
    }
  }
}
.content-header__title {
  padding-bottom: 0;
  margin-bottom: 20px;

  text-align: center;

  font-size: 20px;
  font-weight: 700;
  line-height: 26px;
  letter-spacing: 0.5px;

  color: $colorWhite;

  .content-header--dashboard & {
    padding: 0;
    margin: 0;
    margin-right: 24px;

    color: $colorTextPrimary;
  }

  @media (min-width: $breakpointMd) {
    margin-bottom: 4px;

    text-align: left;
    font-size: 30px;
    line-height: 40px;

    color: $colorTextPrimary;
  }
}

.content-header__caption {
  margin-bottom: 20px;

  font-size: 14px;
  line-height: 21px;
  text-align: center;
  letter-spacing: 0.5px;
  font-weight: 400;

  color: $colorWhite;

  opacity: 0.6;

  @media (min-width: $breakpointMd) {
    margin-top: 4px;
    margin-bottom: 20px;

    text-align: left;

    font-size: 18px;
    line-height: 30px;

    color: $colorTextSecondary;

    opacity: 1;
  }
}
