@import "../../assets/scss/_variables";
@import "../../assets/scss/mixins";

.chart-container {
  display: flex;
  margin-bottom: 24px;

  background: $colorWhite;
  border-radius: 4px;
  box-shadow: 0 2px 4px rgba(30, 50, 90, 0.15);

  box-sizing: border-box;

  @media (max-width: $breakpointMdMax) {
    flex-wrap: wrap;
  }
}

.chart-settings {
  &.chart-settings--common {
    margin-right: 8px;
  }

  &__action-container {
    display: flex;
    justify-content: flex-start;
    align-items: center;

    & > span {
      color: $colorTextPrimary;
    }
  }

  .chart-settings__icon-cont {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 32px;
    height: 32px;
    padding: 6px;
    margin-right: 8px;

    border-radius: 10px;
    cursor: pointer;

    background: rgba($colorBrand, 0.1);
  }

  &__action {
    position: relative;
    margin-top: 20px;

    &--only-x-axis {
      margin-top: 50px;
    }

    &:hover {
      .chart-settings__toolbar-container {
        background-color: $colorWhite;
        display: flex;
      }

      .chart-settings__icon-cont {
        @include box-shadow-1();
        background: $colorWhite;
      }
    }
  }

  .chart-settings__toolbar-container {
    position: absolute;
    //top: 40px;
    z-index: 1150;

    display: none;
    width: 275px;
    border-top: 8px solid transparent;
  }

  &__toolbar {
    @include box-shadow-2();
    display: flex;
    flex-direction: column;
    padding: 16px;
    width: 275px;
  }
}

.chart-toolbar {
  padding: 16px;
  //margin-bottom: 32px;
  width: 193px;
  min-width: 193px;

  background: $colorWhite;
  border-radius: 4px;

  &.toolbar-stat {
    @include box-shadow-1();
    margin-bottom: 0;
  }

  @media (max-width: $breakpointMdMax) {
    width: 100%;
    margin-left: 16px;
    margin-right: 16px;

    &.toolbar-stat {
      margin-bottom: 16px;
    }
  }
}
.chart-timeline__slider {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 12px;

  &-item {
    display: flex;
    align-items: center;
    text-align: center;
    margin-top: 8px;

    font-size: 11px;
    font-weight: 500;
    line-height: 16px;

    letter-spacing: 1px;
    text-transform: uppercase;

    color: $colorTextSecondary;
    cursor: pointer;
  }
}

.chart-switch {
  margin-top: 16px;
}

.chart-slider-container {
  margin-top: 16px;
}

.chart-switch-container {
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  margin-bottom: 8px;

  background-color: $colorWhite;
  border: 1px solid $colorBrand;

  border-radius: 4px;
}

.toolbar-chart--top {
  border-bottom: 1px solid $colorDivider;
}
.toolbar-chart--bottom {
  border-top: 1px solid $colorDivider;
}

.chart-switch-label {
  @include reset-button();
  align-self: normal;
  width: 100%;
  //min-height: 36px;
  padding: 10px 4px;

  background-color: $colorWhite;
  color: $colorTextSecondary;

  font-size: 11px;
  font-weight: 500;
  line-height: 16px;

  letter-spacing: 1px;
  text-transform: uppercase;

  border-radius: 4px;
  cursor: pointer;

  & > svg {
    margin-right: 8px;
  }

  .chart-switch-label__icon {
    margin-right: 12px;
  }

  &.chart-switch-label--icon {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    padding-left: 16px;
    padding-top: 5px;
    padding-bottom: 5px;
  }

  &.chart-switch-label--active {
    background-color: $colorBrand;
    color: $colorWhite;

    box-shadow: 0 3px 6px rgba(104, 133, 238, 0.5);

    &.chart-switch-label--secondary {
      background-color: $colorBrandSecondary;
      color: $colorWhite;
    }
  }
}

.chart-content {
  margin-left: 24px;

  @media (max-width: $breakpointMdMax) {
    margin-left: 0;
  }

  &--only-x-axis {
    position: relative;
    display: flex;
    align-items: flex-end;
    width: 100%;

    .dashboard__chart-title {
      position: absolute;
      top: 0px;
      margin-top: 16px;

      display: flex;
      justify-content: flex-end;
    }
  }
}

.recharts-tooltip-wrapper {
  z-index: 1100;
}

.tooltip-pie {
  padding: 4px;
  background: #28263c;
  /* 2nd Level Shadow */
  box-shadow: 0 4px 16px rgba(30, 50, 90, 0.2);
  border-radius: 4px;

  &__standard {
    margin-bottom: 2px;
    padding: 8px;

    font-size: 12px;
    line-height: 18px;
    /* identical to box height, or 150% */
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    letter-spacing: 0.5px;

    color: $colorWhite;
  }

  &__wif {
    padding: 8px;
    font-size: 12px;
    line-height: 18px;
    /* identical to box height, or 150% */
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    justify-content: center;
    text-align: center;
    letter-spacing: 0.5px;

    color: $colorBrand;

    border-top: 1px solid rgba(232, 234, 236, 0.2);

    &--label {
      width: 100%;
      margin-bottom: 2px;
      font-size: 11px;
      font-weight: 500;
      line-height: 16px;
      display: flex;
      align-items: center;
      justify-content: center;
      text-align: right;
      letter-spacing: 1px;
      text-transform: uppercase;
    }
  }
}

.recharts-default-tooltip--custom {
  @include box-shadow-2();

  margin: 0;
  border-radius: 4px;

  background-color: rgb(255, 255, 255);
  white-space: nowrap;
}

.recharts-tooltip-label--custom {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 8px 16px;
  margin: 0;

  font-size: 14px;
  font-weight: 700;
  line-height: 21px;

  color: $colorTextSecondary;
  border-bottom: 1px solid $colorDivider;

  text-transform: uppercase;
}

.recharts-tooltip-label__year {
  font-size: 11px;
  line-height: 16px;
  font-weight: 500;

  color: $colorTextTetriary;
  text-transform: uppercase;
}

.recharts-tooltip-item-list--custom {
  margin: 0;
  padding: 16px;

  padding-top: 8px;
  padding-bottom: 4px;
}

.recharts-tooltip-item--custom {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-top: 4px;
  padding-bottom: 4px;

  & .recharts-tooltip-item-value {
    margin-left: 32px;
    font-weight: 500;
    text-align: left;
  }
}

.recharts-tooltip-item-events {
  pointer-events: auto;

  display: flex;
  justify-content: flex-start;
  align-items: center;
  margin-top: 0;
  margin-left: 16px;
  margin-right: 16px;
  //margin-bottom: 24px;
  margin-bottom: 12px;
}

.recharts-tooltip-item--total {
  display: flex;
  justify-content: space-between;
  align-items: center;

  padding: 8px 16px;

  background: $colorBackgroundSecondary;
  border-radius: 0 0 4px 4px;

  color: $colorBrand;
  font-size: 14px;
  font-weight: 700;
  line-height: 21px;

  & .recharts-tooltip-item-name {
    font-weight: 700;
  }
  & .recharts-tooltip-item-value {
    margin-left: 32px;
    font-weight: 700;
    text-align: left;
  }

  &-negative {
    background: rgba(255, 59, 48, 0.1);
    color: $colorTextRed;
  }
}
