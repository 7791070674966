@import "../../../assets/scss/variables";
@import "../../../assets/scss/mixins";

.main-toolbar {
  display: flex;
  justify-content: space-between;
  align-items: center;

  margin-top: 16px;
  margin-bottom: 16px;

  @media (max-width: 1150px) {
    flex-wrap: wrap;
  }

  @media (max-width: $breakpointMdMax) {
    // TODO PUSH TO TOP RIGHT TO ICONS
    display: none;
  }
}

.main-toolbar__title {
  @include h2();

  color: $colorTextPrimary;

  @media (max-width: 1150px) {
    width: 100%;
  }
}

.main-toolbar__tools {
  display: flex;
  align-items: center;
  justify-content: space-between;

  @media (max-width: 1150px) {
    width: 100%;
  }
}

.main-toolbar__sort-by {
  display: flex;
  align-items: center;
  margin-right: 40px;
}

.main-toolbar__sort-by-label {
  @include body();

  margin-right: 6px;
  font-weight: 500;
}

////////////////////////////////////////////////////////////////
//TODO move
.dropdown {
  display: inline-block;
}
.dropdown__icon {
  margin-left: 6px;
}

.dropdown__content {
  display: none;
  position: absolute;
}

.dropdown--active .dropdown__content {
  display: block;
}
