@import "./variables";

@mixin reset-ul() {
  margin: 0;
  padding: 0;

  list-style: none;
}

@mixin reset-button() {
  border: none;
  margin: 0;
  padding: 0;
  width: auto;
  overflow: visible;

  background: transparent;

  color: inherit;
  font: inherit;
  cursor: pointer;
}

@mixin reset-fieldset() {
  margin: 0;
  padding: 0;
  border: none;
}

@mixin reset-input() {
  padding: 0;
  border: none;
}

@mixin visually-hidden() {
  border: 0;
  clip: rect(0 0 0 0);
  -webkit-clip-path: inset(50%);
  clip-path: inset(50%);
  height: 1px;
  margin: -1px;
  overflow: hidden;
  padding: 0;
  position: absolute;
  width: 1px;
  white-space: nowrap; /* 1 */
}

@mixin box-shadow-1() {
  box-shadow: 0 2px 4px rgba(30, 50, 90, 0.15);
}

@mixin box-shadow-2() {
  box-shadow: 0 4px 16px rgba(30, 50, 90, 0.2);
}

@mixin box-shadow-3() {
  box-shadow: 0 8px 18px rgba(30, 50, 90, 0.2);
}

@mixin title() {
  font-family: $font;
  font-size: 30px;
  line-height: 40px;
  font-weight: 700;
  letter-spacing: 0.5px;

  color: $colorTextPrimary;
}

@mixin h2() {
  font-family: $font;
  font-size: 20px;
  line-height: 26px;
  font-weight: 700;
  letter-spacing: 0.5px;
}

@mixin body() {
  font-family: $font;
  font-size: 14px;
  line-height: 21px;
  font-weight: 400;
  letter-spacing: 0.5px;
}

@mixin title-with-label() {
  font-size: 11px;
  font-weight: 500;
  line-height: 16px;
  letter-spacing: 1px;

  color: $colorTextTetriary;
  text-transform: uppercase;
}

@mixin title-with-count() {
  font-size: 14px;
  font-weight: 700;
  line-height: 16px;
  letter-spacing: 0.5px;

  color: $colorTextPrimary;
  text-transform: uppercase;
}
