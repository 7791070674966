@import "../../../assets/scss/_variables";
@import "../../../assets/scss/mixins";

.asset-card {
  min-width: 248px;
  position: relative;
  min-height: 50px;

  max-width: 420px;

  &.asset-card--add-dialog {
    width: 100%;
  }
  &.asset-card--add-asset {
    z-index: 100;
    height: 156px;
  }

  &.asset-card--add-short {
    height: auto;
  }

  &.asset-card--add-horizontal {
    height: auto;
    width: 270px;

    @media (min-width: $breakpointGoalAddTile) {
      max-width: 100%;
      width: 100%;
      height: auto;
    }
  }

  &.asset-card--add-asset-show {
    height: auto;
  }

  @media (min-width: $breakpointMd) {
    .content-main--events &:not(.asset-card--selected-mobile) {
      width: 172px;
      min-width: 172px;
    }
  }

  width: 20%;

  @media (max-width: 1850px) {
    width: 25%;
  }

  @media (max-width: 1515px) {
    width: 33.3%;
  }

  @media (max-width: 1235px) {
    width: 50%;
  }

  @media (max-width: 1000px) {
    width: 100%;
  }

  &--cashflow {
    width: 100%;

    min-width: auto;
    max-width: none;
  }

  @media (max-width: $breakpointMdMax) {
    position: static;
    width: 100%;
    max-width: unset;
  }

  &.asset-card--draggable {
    width: 100%;
  }
}

.asset-card__wrapper {
  @include box-shadow-1();

  margin-left: 16px;
  margin-bottom: 16px;

  max-height: 300px;

  background: $colorWhite;
  border-radius: 4px;

  transition: all 0.25s ease-in-out;

  overflow: hidden;

  .asset-card--add-dialog & {
    margin-left: 0;
    margin-bottom: 0;
  }
  .asset-card--add-asset & {
    max-height: none;
  }

  .asset-card--add-horizontal & {
    margin-left: 0;

    @media (min-width: $breakpointGoalAddTile) {
      display: flex;
      box-shadow: none;
      background: none;
    }
  }

  .asset-card--add-event {
    position: absolute;
    z-index: 2000;
  }

  .asset-card.asset-card--selected & {
    @include box-shadow-3();
    max-height: 1000px;

    transition: all 0.25s ease-out;

    .asset-card__top-label {
      top: -18px;
    }
  }

  .asset-card--what-if & {
    background-color: $colorBackgroundSecondary;
  }

  .asset-card--wizard & {
    background-color: #f8f9fa;
  }

  .asset-card--disabled & {
    background-color: $colorBackgroundDisabled;
  }

  @media (min-width: $breakpointMd) {
    .asset-card.asset-card--selected & {
      position: absolute;
      z-index: 200;
    }
  }

  @media (max-width: $breakpointMdMax) {
    margin-bottom: 16px;
    width: calc(100% - 32px);
    margin-left: 16px;
    margin-right: 16px;

    text-align: left;
  }
}

.asset-card__top {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 10px 16px;
  height: 57px;
  border-bottom: 1px solid $colorDivider;

  .asset-card--add-asset & {
    color: $colorWhite;
    background-color: $colorUiYellow;
  }

  .asset-card--add-horizontal & {
    @media (min-width: $breakpointGoalAddTile) {
      height: auto;
    }
  }

  &-label {
    z-index: 1;
    position: absolute;
    top: 0;
    right: 0;
    display: flex;
    align-items: center;
    padding: 3px 7px 1px;
    color: $colorWhite;
    background: $colorBrand;
    border-radius: 4px;
    font-weight: 500;
    font-size: 12px;
    line-height: 18px;
    letter-spacing: 0.5px;

    span {
      margin-left: 4px;
      margin-bottom: 3px;
    }

    &--warn {
      color: $colorWhite;
      background: $colorUiYellow;
    }

    .asset-card--selected & {
      top: -17px;
      right: -16px;
      z-index: 300;
    }
  }
}

.asset-card__top-alert {
  position: absolute;
  top: 8px;
  left: 36px;
  z-index: 2;
}

.asset-card__title {
  flex-grow: 1;
  width: 55%;
  margin-left: 10px;
  margin-right: 0;

  font-size: 14px;
  line-height: 16px;
  letter-spacing: 0.5px;
  font-weight: 500;

  color: $colorTextSecondary;

  text-transform: uppercase;

  .asset-card--selected & {
    margin-right: 32px;
  }

  .asset-card--disabled & {
    color: $colorTextTetriary;
  }

  @media (max-width: $breakpointMdMax) {
    margin-right: auto;
  }
}

.asset-card__sub-title {
  font-size: 11px;
  line-height: 16px;
  letter-spacing: 1px;

  color: $colorTextTetriary;

  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;

  &__dsc {
    color: $colorBrand;
    font-size: 12px;
    line-height: 18px;
    letter-spacing: 0.5px;
    text-transform: capitalize;
  }
}

.asset-card__title-dsc {
  margin-top: 2px;

  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;

  .asset-card--add-asset & {
    color: $colorWhite;
  }
}

.asset-card__dropdown {
  display: none;
  opacity: 0;
  text-align: right;

  .asset-card.asset-card--selected & {
    display: block;
    opacity: 1;
  }

  @media (max-width: $breakpointMdMax) {
    display: block;

    opacity: 1;
    padding-left: 20px;
  }
}

.asset-card__content {
  padding: 10px 16px;
}

.asset-card__fields {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-between;
  margin-left: -4px;
}

.asset-card__field {
  margin-left: 4px;
  flex-grow: 1;

  width: 47%;
  margin-right: auto;

  margin-bottom: 20px;

  &.asset-card__field--full-width {
    width: 100%;
  }

  &.asset-card__field--third-width {
    width: 30%;
  }

  &.asset-card__field--group-label {
    margin-bottom: 15px;
    padding-bottom: 8px;
    border-bottom: 1px solid $colorDivider;

    font-size: 11px;
    line-height: 16px;
    letter-spacing: 1px;
    text-transform: uppercase;

    color: $colorTextTetriary;
  }
}

.asset-card__label {
  font-size: 11px;
  line-height: 16px;
  letter-spacing: 1px;
  text-transform: uppercase;

  color: $colorTextTetriary;
}
.asset-card__value a,
.asset-card__value {
  font-size: 18px;
  line-height: 27px;
  letter-spacing: 0.5px;

  font-weight: 700;
  color: $colorTextPrimary;

  text-decoration: none;

  &.asset-card__value--secondary a,
  &.asset-card__value--secondary {
    font-size: 14px;
    line-height: 21px;
    font-weight: 400;
  }

  .asset-card--disabled & {
    color: $colorTextTetriary;
  }
}

.asset-card__value a:hover {
  text-decoration: underline;
}

.asset-card__total {
  .asset-card.asset-card--selected &,
  .asset-card.asset-card--selected-mobile & {
    display: none;
  }
}

.asset-card__more {
  display: none;

  .asset-card.asset-card--selected &,
  .asset-card.asset-card--selected-mobile & {
    display: block;
  }
}
