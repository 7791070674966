@import "../../../assets/scss/variables";
@import "../../../assets/scss/mixins";

.side-group {
  background-color: $colorWhite;

  &.side-group--secondary {
    background-color: $colorBackgroundSecondary;
  }

  &.side-group--not-first {
    margin-top: 44px;
  }
}

.side-group__title {
  padding-bottom: 6px;
  margin-bottom: 16px;
  margin-left: 24px;
  margin-right: 24px;

  font-size: 11px;
  line-height: 16px;
  letter-spacing: 1px;
  text-transform: uppercase;
  font-weight: 500;

  color: $colorTextTetriary;

  border-bottom: 1px solid $colorDivider;

  @media (max-width: $breakpointMdMax) {
    margin-bottom: 2px;
  }
}
