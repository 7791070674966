@import "../../assets/scss/_variables";
@import "../../assets/scss/mixins";

.top-mobile {
  position: fixed;
  z-index: 1000;
  top: 0;
  width: 100vw;
  height: 56px;
  background: linear-gradient(180deg, #363658 0%, #432f70 100%);

  //border-bottom: 1px solid rgba($colorWhite, 0.2);

  &::after {
    content: "";
    display: block;
    margin-left: 8px;
    margin-right: 8px;
    height: 1px;
    background-color: rgba($colorWhite, 0.2);
  }
}

.top-mobile__wrapper {
  display: flex;
  align-items: center;
  height: 100%;
}

.top-mobile__drawer-button {
  @include reset-button();
  width: 56px;
  height: 56px;

  padding: 16px;
}

.top-mobile__title {
  font-size: 18px;
  line-height: 26px;
  letter-spacing: 0.5px;

  color: $colorWhite;
}
