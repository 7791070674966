@import "../../assets/scss/_variables";
@import "../../assets/scss/mixins";

.asset-card--add-asset:hover {
  z-index: 110;
}

.asset-card__tiles-type {
  height: 84px;
  -webkit-mask-image: -webkit-gradient(
    linear,
    left top,
    left bottom,
    from(rgba(0, 0, 0, 1)),
    to(rgba(0, 0, 0, 0.3))
  );

  .asset-card--add-asset:hover &,
  .asset-card--add-asset.asset-card--add-asset-show & {
    height: auto;
    -webkit-mask-image: none;
  }

  .asset-card--add-horizontal & {
    height: 0;
    @media (min-width: $breakpointGoalAddTile) {
      height: auto;
      width: 100%;
      background: white;
      -webkit-mask-image: none;
    }
  }
}

.assets-add__container {
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-self: center;

  padding: 0;

  .asset-card--add-dialog & {
    position: static !important;
    margin-left: 0;
  }

  .asset-card--add-asset:hover &,
  .asset-card--add-asset.asset-card--add-asset-show & {
    @include box-shadow-3();

    position: absolute;
    z-index: 1000;
    background: white;
    left: 16px;
    right: 0;
    border-radius: 0px 0px 4px 4px;

    .toolbar-buttons__add-tile--categories & {
      position: static;
    }

    @media (max-width: $breakpointMdMax) {
      //margin-left: 25px;
      //margin-right: 40px;

      margin-left: 16px;
      margin-right: 45px;
    }
  }

  .asset-card--add-asset.asset-card--add-horizontal & {
    left: 0;

    @media (min-width: $breakpointGoalAddTile) {
      position: static;

      flex-wrap: wrap;
      flex-direction: row;
      justify-content: flex-start;
    }
  }
}

.asset__add-item {
  @include reset-button();
  //display: none;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  align-self: flex-start;
  width: calc(100% - 24px);

  padding: 8px 4px;
  margin-left: 12px;
  margin-right: 12px;
  cursor: pointer;

  &:first-child {
    margin-top: 8px;
  }
  &:last-child:not(.asset__add-item--general) {
    margin-bottom: 8px;
  }

  @media (min-width: $breakpointMd) {
    &:hover {
      background: rgba($colorTextTetriary, 0.1);
      border-radius: 4px;
    }
  }

  &--general {
    width: 100%;
    margin-top: 16px;
    margin-bottom: 0;
    margin-left: 0;
    margin-right: 0;
    padding: 16px;

    background-color: $colorBackgroundSecondary;
  }

  .asset-card--add-horizontal & {
    @media (min-width: $breakpointGoalAddTile) {
      width: auto;
      margin-top: 8px;
      margin-bottom: 8px;

      &--general {
        width: auto;
        margin-top: 8px;
        margin-bottom: 8px;
        padding: 8px 4px;

        background: none;
        &:hover {
          background: rgba($colorTextTetriary, 0.1);
          border-radius: 4px;
        }
      }
    }
  }
}

.asset__add-item--icon {
  margin-right: 10px;
}

.asset__add-item--title {
  font-size: 12px;
  font-weight: 500;
  line-height: 16px;
  display: flex;
  text-align: left;
  letter-spacing: 0.5px;
  text-transform: uppercase;

  color: $colorTextSecondary;
}

.cards {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: stretch;
  align-items: flex-start;
  align-content: stretch;

  @media all and (-ms-high-contrast: none) {
    /* IE11 */
    *::-ms-backdrop,
    & {
      width: calc(100vw - 675px);
    }
  }

  margin-left: -16px;

  &.cards--goals {
    margin-left: 0;
  }

  padding-bottom: 120px;

  .main-container--dashboard & {
    padding-bottom: 24px;
  }

  &.cards--slider {
    margin-left: 0;

    @media (max-width: $breakpointMdMax) {
      .main-container--dashboard & {
        display: block;
      }
    }
  }

  @media (max-width: $breakpointMdMax) {
    flex-direction: column;
    width: 100%;
    margin-left: 0;
    padding-bottom: 45px;

    .main-content--dialog & {
      margin-top: 60px;
    }

    &.cards--slider {
      margin-left: 0;
    }
  }
}

.warning-wizard__text {
  font-size: 16px;
  font-weight: 500;
  line-height: 20px;
  color: $colorTextPrimary;
}
