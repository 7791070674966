@import "../../assets/scss/_variables";

.content-main {
  @media (max-width: $breakpointMdMax) {
    margin-top: -56px;

    align-items: center;

    &.content-main--wo-gap {
      margin-top: 0;
    }

    &.content-main--events,
    .main-container--dashboard & {
      margin-top: 0;
      margin-left: 16px;
      margin-right: 16px;
      margin-bottom: 80px;
    }
  }

  @media (min-width: $breakpointMd) {
    &.content-main--events {
      width: calc(100vw - 335px);
      max-width: 100vw;
    }
    .dashboard__combine &.content-main--events {
      width: calc(100vw - 580px);
    }

    .dashboard__combine {
      .main-content {
        padding-left: 20px;
        padding-right: 0;
        padding-bottom: 0;
      }
    }

    .drawer-closed &.content-main--events {
      //width: calc(100vw - 210px);
      width: calc(100vw - 140px);
    }

    .drawer-closed .dashboard__combine &.content-main--events {
      width: calc(100vw - 395px);
      min-height: 380px;
    }

    &.content-main--operations {
      width: calc(100vw - 325px);
      max-width: 100vw;

      &.content-main--operations-dashboard {
        padding-top: 14px;
        padding-left: 20px;
        width: calc(100vw - 540px);
      }
    }

    .drawer-closed &.content-main--operations {
      width: calc(100vw - 100px);

      &.content-main--operations-dashboard {
        width: calc(100vw - 325px);
      }
    }
  }
}
