@import "../../../assets/scss/variables";
@import "../../../assets/scss/mixins";

.form-control__wrapper.form-control__wrapper-configurations {
  width: calc(100% + 48px);
  height: 2px;
  margin-bottom: 16px;
  margin-left: -24px;
  margin-right: -24px;
  background-color: rgba($colorUiYellow, 0.2);
}

.form-control__wrapper {
  position: relative;

  width: 100%;

  &.form-control__wrapper--half {
    width: 45%;
  }

  &.form-control__wrapper--third {
    width: 30%;
  }
}

.form-label {
  position: absolute;
  top: 0;
  left: 0;

  font-size: 12px;
  line-height: 18px;
  letter-spacing: 0.5px;

  color: $colorFieldLabel;

  .form-control:focus + & {
    color: $colorUi;
  }

  .form-control:required + &::after {
    content: "*";
    display: inline-block;
    color: $colorTextRed;
  }
}

.form-control {
  @include reset-input();

  position: relative;
  top: 20px;

  width: 100%;
  margin-bottom: 35px;
  padding-bottom: 4px;

  font-family: $font;
  font-size: 16px;
  line-height: 24px;
  letter-spacing: 0.5px;

  color: $colorTextPrimary;
  //border-bottom: 1px solid rgba(139, 150, 160, 0.5);
  border-bottom: 1px solid $colorDivider;

  outline: none;

  &:hover,
  &:focus {
    border-bottom-color: $colorUi;
  }

  &.form-control--bg-secondary {
    background-color: $colorBackgroundSecondary;
  }
}

select.form-control {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  //background:  96% / 15% no-repeat
  //  #fff;

  background-color: #fff;
  background-image: url(/assets/img/icon/svg/select_arrow.svg);
  background-position: 97% center;
  background-repeat: no-repeat;

  //CAUTION: IE hackery ahead
  &::-ms-expand {
    display: none; /* remove default arrow on ie10 and ie11 */
  }
  @media screen and (min-width: 0\0) {
    & {
      background: none\9;
      padding: 5px\9;
    }
  }
}
