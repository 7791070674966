@import "../../../assets/scss/variables";
@import "../../../assets/scss/mixins";

.button-side-item {
  @include reset-button();

  display: flex;
  justify-content: center;
  align-items: center;

  margin-bottom: 3px;
  padding: 6px;
  //width: 32px;
  //height: 32px;

  border-radius: 4px;

  & svg path {
    fill-opacity: 0.5;
  }

  &:hover svg path {
    fill-opacity: 1;
  }

  &:not(.button-side-item--not-hovered):hover,
  &:not(.button-side-item--not-hovered):focus {
    //background: rgba($colorTextTetriary, 0.15);
  }

  &.button-side-item--top-right {
    position: absolute;
    top: 16px;
    right: 16px;
  }

  .side-item__top:hover & {
    & svg path {
      fill-opacity: 1;
    }
  }

  .add-form__top--full-handle:hover & {
    svg path {
      fill-opacity: 1;
    }
  }
}
