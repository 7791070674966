@import "./_variables";
@import "main";

@font-face {
  font-family: "PT Root UI";
  src: local("PT Root UI Medium"), local("PTRootUI-Medium"),
    url("/assets/fonts/Ptrootuimedium.woff2") format("woff2"),
    url("/assets/fonts/Ptrootuimedium.woff") format("woff");
  font-weight: 500;
  font-style: normal;
  font-display: swap;
}
@font-face {
  font-family: "PT Root UI";
  src: local("PT Root UI Light"), local("PTRootUI-Light"),
    url("/assets/fonts/Ptrootuilight.woff2") format("woff2"),
    url("/assets/fonts/Ptrootuilight.woff") format("woff");
  font-weight: 300;
  font-style: normal;
  font-display: swap;
}
@font-face {
  font-family: "PT Root UI";
  src: local("PT Root UI"), local("PTRootUI-Regular"),
    url("/assets/fonts/Ptrootui.woff2") format("woff2"),
    url("/assets/fonts/Ptrootui.woff") format("woff");
  font-weight: 400;
  font-style: normal;
  font-display: swap;
}
@font-face {
  font-family: "PT Root UI";
  src: local("PT Root UI Bold"), local("PTRootUI-Bold"),
    url("/assets/fonts/Ptrootuibold.woff2") format("woff2"),
    url("/assets/fonts/Ptrootuibold.woff") format("woff");
  font-weight: 700;
  font-style: normal;
  font-display: swap;
}

//.disable-hover,
//.disable-hover * {
//  pointer-events: none !important;
//}

body {
  //min-width: 1000px;
  margin: 0;
  padding: 0;

  font-family: $font;
  font-size: 14px;
  line-height: 21px;
  font-weight: 400;
  letter-spacing: 0.5px;

  color: $colorTextSecondary;

  background-color: $colorBackground;
}

picture {
  margin: 0;
  padding: 0;
}

*:focus {
  outline: none;
}

img {
  max-width: 100%;
  height: auto;
}

.visually-hidden.visually-hidden {
  border: 0;
  clip: rect(0 0 0 0);
  -webkit-clip-path: inset(50%);
  clip-path: inset(50%);
  height: 1px;
  margin: -1px;
  overflow: hidden;
  padding: 0;
  position: absolute;
  width: 1px;
  white-space: nowrap; /* 1 */
}

input[type]:-webkit-autofill {
  background-color: transparent !important;
}

.add-form input:-webkit-autofill {
  -webkit-box-shadow: inset 0 0 0 50px $colorBackgroundSecondary !important; /* Цвет фона */
}

input[type="number"]::-webkit-inner-spin-button,
input[type="number"]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
input[type="number"] {
  -moz-appearance: textfield;
}

@keyframes autofill {
  to {
    background: #ffffff;
  }
}

// fix event bubbling iOS
@supports (-webkit-overflow-scrolling: touch) {
  body {
    cursor: pointer;

    -webkit-tap-highlight-color: transparent;
  }
}

input:-webkit-autofill {
  -webkit-animation-name: autofill;
  -webkit-animation-fill-mode: both;
}

.text-upper {
  text-transform: uppercase;
}

.tooltip-content {
  user-select: none;
}

.tooltip-span {
  white-space: pre-line;
}

.cursor-pointer {
  cursor: pointer;
}

.text-right {
  text-align: right !important;
}

.text-left {
  text-align: left !important;
}
//@media (hover: none) {
//  li[class*="MuiListItem-button"]:hover:not(.important):not(.important):not(.important) {
//    background-color: transparent;
//    text-decoration: none;
//    //background-color: yellow;
//  }
//}
