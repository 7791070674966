@import "../../../assets/scss/variables";
@import "../../../assets/scss/mixins";

.empty-panel {
  position: relative;

  height: 256px;
  width: 100%;

  border-radius: 4px;
  background-color: rgba($colorUi, 0.1);

  //background-image: url(/assets/img/emptyPanel.svg);
  //background-repeat: no-repeat;
  //background-size: 100% 100%;
  //background-position: center center;

  background-image: url(/assets/img/icon/emptyPanel@x3.png);

  background-position: center 32px;
  background-repeat: no-repeat;
  background-size: 240px 120px;

  @media (max-width: 1440px) {
    background-position: center 32px;
  }

  @media (max-width: 1370px) {
    background-position: center 32px;
  }

  @media (max-width: 1260px) {
    background-image: url(/assets/img/icon/emptyPanel@x3.png);

    background-position: center 32px;
    background-repeat: no-repeat;
    background-size: 240px 120px;
  }

  @media (max-width: $breakpointMdMax) {
    height: 375px;

    margin-top: 56px;
    background-image: url(/assets/img/icon/emptyPanel@x3.png);
    background-repeat: no-repeat;
    background-position: center top 30px;
    background-size: 240px 120px;
  }
}

.empty-panel__title {
  position: absolute;
  left: 0;
  right: 0;
  bottom: 34px;

  margin: 0 auto;

  max-width: 600px;
  font-weight: 500;

  font-size: 18px;
  line-height: 24px;
  letter-spacing: 0.5px;
  text-align: center;

  color: $colorBrand;

  white-space: pre;

  @media (max-width: 1500px) {
    white-space: normal;
  }

  @media (max-width: $breakpointMdMax) {
    top: 166px;
    bottom: auto;
  }
}
