@import "../../assets/scss/_variables";
@import "../../assets/scss/mixins";

.profile-toolbar {
  display: flex;
  justify-content: space-between;
  align-items: baseline;
}

.household__help-text {
  margin: 0;
  padding: 0;

  margin-bottom: 4px;

  font-size: 14px;
  line-height: 21px;
  letter-spacing: 0.5px;
  text-align: left;

  color: $colorTextSecondary;

  @media (max-width: $breakpointMdMax) {
    margin-bottom: 20px;

    font-size: 14px;
    line-height: 21px;
    text-align: center;

    color: $colorWhite;

    opacity: 0.6;
  }
}

.household__why-need-button {
  @include reset-button();

  margin-bottom: 21px;

  font-size: 14px;
  line-height: 16px;
  letter-spacing: 0.5px;
  text-decoration-line: underline;

  color: $colorUi;

  @media (max-width: $breakpointMdMax) {
    text-align: center;
    color: $colorWhite;
  }
}

.household__forms {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  //flex-wrap: wrap;
  align-items: flex-start;

  &.household__forms--profile {
    flex-wrap: wrap;
  }

  @media (max-width: $breakpointMdMax) {
    flex-direction: column;
  }
}

.button--members-save {
  @media (max-width: $breakpointMdMax) {
    bottom: 70px;
  }
}

.household__action-container {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 2px;
}

.household__action-text {
  margin-right: 10px;

  font-size: 14px;
  line-height: 21px;
  letter-spacing: 0.5px;
  font-weight: 500;

  color: $colorBrand;
  opacity: 0.5;

  .button-side-item:hover & {
    opacity: 1;
  }

  &.household__action-text--tetriary {
    color: $colorTextTetriary;
  }
}

.profile-advisor {
  display: flex;
  flex-direction: column;
}
