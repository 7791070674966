@import "../../../assets/scss/_variables";
@import "../../../assets/scss/mixins";

.add-form {
  @include box-shadow-1();

  margin-bottom: 16px;
  margin-right: 20px;

  background-color: $colorWhite;
  border-radius: 4px;

  &--main {
    background-color: $colorBackgroundSecondary;
  }
}

.add-form__top {
  display: flex;
  justify-content: space-between;
  align-items: center;

  //margin-left: 24px;
  //margin-right: 24px;
  padding-left: 24px;
  padding-right: 24px;
  padding-top: 16px;
  padding-bottom: 16px;
  border-bottom: 1px solid $colorDivider;

  color: $colorTextSecondary;

  cursor: pointer;

  &.add-form__top--static {
    cursor: default;
  }

  .add-form--main & {
    color: $colorBrand;
    border-bottom: 1px solid rgba($colorUi, 0.2);
  }

  .add-form--ui & {
    //color: $colorUi;
    color: $colorTextSecondary;
    border-bottom: 1px solid rgba($colorUi, 0.2);
  }

  .add-form--yellow & {
    color: $colorUiYellow;
    border-bottom: 1px solid rgba($colorUiYellow, 0.2);
  }

  &.add-form__top--full-handle {
    cursor: pointer;
  }

  &.add-form__top--full-handle:hover {
    border-top-right-radius: 4px;
    border-top-left-radius: 4px;
    background-color: #f0f3fd;
    background-image: linear-gradient(
      0deg,
      rgba(106, 104, 251, 0.15),
      rgba(106, 104, 251, 0.15)
    );
  }
}

.add-form__top-title {
  font-size: 14px;
  line-height: 16px;
  letter-spacing: 0.5px;
  text-transform: uppercase;
  font-weight: 500;
}

.add-form__top-action {
  &:hover {
  }
}

.add-form__content {
  padding: 24px 24px 32px 24px;

  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;

  & > * {
    width: 100%;
  }
}
