@import "../../../assets/scss/variables";
@import "../../../assets/scss/mixins";

.side-item {
  position: relative;

  margin-bottom: 16px;

  @media (max-width: $breakpointMdMax) {
    margin-bottom: 0;
  }
}

.side-item__wrapper {
  padding-left: 24px;
  padding-right: 24px;

  &:not(.side-item__wrapper--general).side-item__wrapper--open {
    @include box-shadow-2();

    position: relative;
    margin-top: -17px;
    margin-left: 8px;
    margin-right: 8px;
    padding: 17px 16px 40px 16px;

    box-sizing: border-box;
    border-radius: 4px;
    border: 1px solid $colorDivider;
  }

  &.side-item__wrapper--full-height {
    @media (max-width: $breakpointMdMax) {
      height: 100vh;
    }
  }

  &.side-item__wrapper--general {
    padding-top: 17px;
    padding-bottom: 24px;

    background-color: $colorBackgroundSecondary; // TODO maybe need bgc to all panel

    & .side-item__top {
      border: none;
    }
  }
}

.side-item__top {
  display: flex;
  align-items: center;
  padding-bottom: 16px;

  border-bottom: 1px solid $colorDivider;

  cursor: pointer;

  @media (max-width: $breakpointMdMax) {
    padding-top: 12px;

    &--edit {
      padding-left: 24px;
    }
  }
}

.side-item__icon {
  margin-right: 10px;
}

.side-item__button-back {
  display: none;

  @media (max-width: $breakpointMdMax) {
    display: block;
    height: 24px;
    margin-right: 12px;
  }
}

.side-item__label {
  flex-grow: 1;

  text-transform: uppercase;
  font-weight: 500;

  color: $colorTextSecondary;

  @media (min-width: $breakpointMd) {
    .side-item__top:hover & {
      color: $colorTextPrimary;
    }
  }

  &-category {
    font-size: 11px;
    line-height: 16px;
    letter-spacing: 1px;
    color: #85859a;
  }
}

.side-item__caption {
  margin-top: 10px;

  font-size: 12px;
  line-height: 18px;
  letter-spacing: 0.5px;

  color: $colorTextSecondary;
}

.side-item__button {
  @include reset-button();

  display: flex;
  justify-content: center;
  align-items: center;
  width: 32px;
  height: 32px;

  border-radius: 4px;

  @media (min-width: $breakpointMd) {
    &:hover,
    &:focus {
      background: rgba(rgba(51, 52, 86, 0.6), 0.15);
    }
  }
}

.side-item__content {
  margin-top: 24px;
}
