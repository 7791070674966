@import "../../../assets/scss/_variables";
@import "../../../assets/scss/mixins";

.ac-draggable__list {
  width: 100%;
}

.goals-labels {
  display: flex;
  width: 100%;
  margin-bottom: 6px;

  @media (max-width: $breakpointMdMax) {
    display: none;
  }
}

.goals-labels__type,
.goals-labels__amount,
.goals-labels__description {
  color: $colorTextTetriary;

  font-size: 11px;
  line-height: 16px;
  letter-spacing: 1px;
  font-weight: 500;

  text-transform: uppercase;
}

.goals-labels__type {
  margin-left: 107px;
}

.goals-labels__amount {
  margin-left: 270px;
}

.goals-labels__description {
  margin-left: 90px;
}

.ac-draggable {
  width: 100%;
}

.ac-draggable__wrapper {
  margin-bottom: 8px;

  background: $colorWhite;
  border-radius: 4px;

  transition: box-shadow 0.3s ease-in-out;
  border: 1px solid $colorDivider;

  cursor: pointer;

  &.ac-draggable__wrapper--expanded {
    @include box-shadow-1();
  }

  @media (max-width: $breakpointMdMax) {
    display: flex;
    flex-direction: column;

    margin-bottom: 16px;
    margin-left: 16px;
    margin-right: 16px;
    width: calc(100% - 32px);

    text-align: left;
  }
}

.ac-draggable__top {
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  padding: 10px 16px;

  @media (max-width: $breakpointMdMax) {
    border-bottom: 1px solid $colorDivider;
  }
}

.ac-draggable__title {
  margin-top: 10px;
  margin-left: 10px;
  margin-right: 85px;
  //width: 160px;
  width: 216px;

  font-size: 14px;
  line-height: 16px;
  letter-spacing: 0.5px;
  font-weight: 500;

  color: $colorTextSecondary;

  text-transform: uppercase;

  @media (max-width: $breakpointMdMax) {
    margin-right: auto;
  }
}

.ac-draggable__title-dsc {
  margin-top: 2px;

  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}

.ac-draggable__dsc {
  margin-left: 72px;
  margin-top: 5px;
  font-size: 16px;
  line-height: 24px;

  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  max-width: 300px;

  .ac-draggable__wrapper--expanded & {
    text-overflow: unset;
    white-space: unset;
    overflow: unset;
  }
}

.ac-draggable__drag {
  margin-top: 5px;
  width: 24px;
}

.ac-draggable__drag-stub {
  margin-top: 5px;
  width: 24px;
}

.ac-draggable__icon-tile {
  margin-top: 2px;
  width: 32px;
  margin-left: 24px;
}

.ac-draggable__sub-title {
  font-size: 11px;
  line-height: 16px;
  letter-spacing: 1px;

  color: $colorTextTetriary;
}

.ac-draggable .ac-draggable__dropdown {
  margin-left: auto;
  text-align: right;
  opacity: 1;

  @media (max-width: $breakpointMdMax) {
    padding-left: 20px;
  }
}

.ac-draggable__content {
  padding: 10px 16px;
}

.ac-draggable__fields-container {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;

  margin-top: 4px;
  width: 80px;

  .ac-draggable__wrapper--expanded & {
    min-width: 80px;
    width: auto;
  }

  @media (max-width: $breakpointMdMax) {
    display: flex;
    //flex-direction: row;
    flex-direction: column;
    justify-content: space-between;
    width: auto;

    margin-top: 12px;
    margin-left: 64px;
    margin-right: 24px;
    margin-bottom: 16px;
    //padding-bottom: 16px;
  }
}

.ac-draggable__field--dsc {
  @media (max-width: $breakpointMdMax) {
    display: none;

    .ac-draggable__wrapper--expanded & {
      display: block;
      margin-left: 64px;
      max-width: calc(100% - 80px);
    }
  }
}

.ac-draggable__fields {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-between;
  margin-left: -4px;

  @media (min-width: $breakpointMd) {
    flex-direction: column;
    min-width: 120px;
  }
}

.ac-draggable__field {
  margin-left: 4px;
  flex-grow: 1;

  margin-right: auto;

  .ac-draggable__wrapper--expanded & {
    margin-bottom: 20px;
  }

  .ac-draggable__wrapper--expanded .ac-draggable__more &:last-child {
    margin-bottom: 0;

    @media (max-width: $breakpointMdMax) {
      width: 47%;
      //margin-bottom: 20px;
    }
  }

  &.ac-draggable__field--full-width {
    width: 100%;
  }

  &.ac-draggable__field--third-width {
    width: 30%;
  }

  &.ac-draggable__field--group-label {
    margin-bottom: 15px;
    padding-bottom: 8px;
    border-bottom: 1px solid $colorDivider;

    font-size: 11px;
    line-height: 16px;
    letter-spacing: 1px;
    text-transform: uppercase;

    color: $colorTextTetriary;
  }
}

.ac-draggable__label {
  font-size: 11px;
  line-height: 16px;
  letter-spacing: 1px;
  text-transform: uppercase;

  color: $colorTextTetriary;

  &.ac-draggable__label--dsc {
    display: none;
  }

  &.ac-draggable__label--main {
    display: none;

    @media (max-width: $breakpointMdMax) {
      display: block;
    }
  }

  .ac-draggable__wrapper--expanded &.ac-draggable__label--main,
  .ac-draggable__wrapper--expanded &.ac-draggable__label--dsc {
    display: block;
  }
}

.ac-draggable__value {
  font-size: 18px;
  line-height: 27px;
  letter-spacing: 0.5px;

  font-weight: 700;

  &.ac-draggable__value--secondary {
    font-size: 14px;
    line-height: 21px;
    font-weight: 400;
  }
}

.ac-draggable__more {
  display: none;

  @media (max-width: $breakpointMdMax) {
    .ac-draggable__wrapper--expanded & {
      display: block;
    }
  }

  .ac-draggable__wrapper--expanded & {
    display: block;
  }
}
