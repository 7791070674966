@import "../../assets/scss/_variables";
@import "../../assets/scss/mixins";

.app-bar-link {
  position: relative;
  display: block;
  margin-right: 40px;
  padding-top: 16px;
  padding-bottom: 16px;

  font-size: 15px;
  font-weight: 500;
  line-height: normal;
  text-decoration: none;
  white-space: nowrap;

  color: $colorTextTetriary;

  cursor: pointer;

  & .app-bar-link__title {
    opacity: 0.9;
  }

  &:hover > .app-bar-link__title {
    opacity: 0.6;
  }

  &.active {
    color: $colorTextPrimary;
  }

  &.active .mark {
    position: absolute;
    top: 56px;

    content: "";
    display: block;
    width: 100%;
    height: 2px;
    background: rgba(7, 5, 29, 0.85);
    border-radius: 2px 2px 0px 0px;
  }

  &.app-bar-link--active > .app-bar-submenu,
  &:hover > .app-bar-submenu,
  &:focus > .app-bar-submenu {
    display: block;
  }

  &__title--client {
    display: flex;
    align-items: center;
    padding: 6px;
    border-radius: 4px;

    &:hover {
      background-color: rgba($colorUi, 0.2);
    }

    & > svg > path {
      fill: $colorTextTetriary;
    }

    &-active {
      & > svg > path {
        fill: $colorUi;
      }
    }
  }

  &--provider {
    margin-right: 16px;
  }
}
.app-bar__client {
  margin-left: 8px;
  margin-top: -4px;
  color: $colorUi;

  &--reset {
    cursor: pointer;
    margin-left: -8px;
    margin-top: 4px;
    margin-right: 16px;

    &:hover,
    &:focus {
      opacity: 0.7;
    }

    &:active {
      opacity: 0.5;
    }

    & > svg {
      & > path {
        fill: $colorTextRed;
      }
    }
  }
}
.app-bar-submenu {
  @include reset-ul();
  display: none;

  padding-top: 8px;
  padding-bottom: 8px;

  opacity: 1;

  box-shadow: 0px 8px 18px rgba(30, 50, 90, 0.2);
  border-radius: 4px;
  background-color: #fff;

  position: absolute;
  z-index: 10;

  top: 46px;

  & > a {
    text-decoration: none;
    color: inherit;
  }

  &__item {
    display: flex;
    align-items: center;

    padding: 14px 18px;

    color: $colorTextPrimary;
    width: auto;
    height: 24px;
    //overflow: hidden;
    font-size: 14px;
    box-sizing: content-box;
    font-weight: 400;
    line-height: 16px;
    white-space: nowrap;
    letter-spacing: 0.5px;

    &:hover {
      background-color: rgba(0, 0, 0, 0.08);
    }

    & > svg {
      margin-right: 8px;
    }

    & > span + svg {
      margin-left: auto;
      padding-left: 8px;
    }

    &:hover > .app-bar-submenu {
      display: block;
    }

    &.app-bar-submenu__item--sub {
      position: relative;
    }

    &.app-bar-submenu__item--sub-bottom > ul {
      top: -112px;
    }

    & > .app-bar-submenu--2 {
      top: -8px;
      left: calc(100% - 6px);
    }

    &--client {
      display: flex;
      justify-content: space-between;
      align-items: center;
    }

    & .app-bar-submenu__item-button {
      margin-left: 24px;
    }
  }
}

.app-bar-link--list {
  text-decoration: none;
}
