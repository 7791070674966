@import "../../assets/scss/variables";
@import "../../assets/scss/mixins";

.my-advisor__container {
  margin-bottom: 20px;
}

.my-advisor__item {
  display: flex;
  flex-direction: column;
}

.my-advisor__item-title {
  margin-bottom: 2px;
  font-size: 11px;
  font-weight: 500;
  line-height: 16px;
  letter-spacing: 1px;

  color: $colorTextTetriary;
  text-transform: uppercase;

  &--primary {
    display: flex;
    justify-content: space-between;

    margin-bottom: 18px;
    padding-bottom: 6px;
    border-bottom: 1px solid $colorDivider;
  }

  &--ui {
    color: $colorUi;
    border-bottom-color: $colorUi;
  }
  &--green {
    color: $colorTextGreen;
    border-bottom-color: $colorTextGreen;
  }
  &--red {
    color: $colorTextRed;
    border-bottom-color: $colorTextRed;
  }
  &--yellow {
    color: $colorUiYellow;
    border-bottom-color: $colorUiYellow;
  }
}

.my-advisor__item-title-value {
  color: $colorTextSecondary;

  .my-advisor__item-title--ui & {
    color: $colorUi;
  }
  .my-advisor__item-title--green & {
    color: $colorTextGreen;
  }
  .my-advisor__item-title--red & {
    color: $colorTextRed;
  }
  .my-advisor__item-title--yellow & {
    color: $colorUiYellow;
  }
}

.my-advisor__item-content {
  display: flex;
  justify-content: space-between;
  align-items: center;

  margin-bottom: 12px;
}

.my-advisor__item-content-half-divider {
  margin-bottom: 16px;
}

.hhcard.my-advisor__item-container {
  box-shadow: none;

  @media (max-width: $breakpointMdMax) {
    margin-left: 32px;
  }
}
.my-advisor__item-container--first {
  border-radius: 4px 0 0 4px;

  @media (max-width: $breakpointMdMax) {
    border-radius: 4px 4px 0 0;
  }
}
.my-advisor__item-container--second {
  border-radius: 0;

  @media (max-width: $breakpointMdMax) {
    border-radius: 0;
  }
}
.my-advisor__item-container--third {
  border-radius: 0 4px 4px 0;

  @media (max-width: $breakpointMdMax) {
    border-radius: 0 0 4px 4px;
  }
}

.my-advisor__item-content-divider {
  margin-bottom: 32px;
}

.my-advisor__item-label {
  display: flex;
  align-items: center;

  font-size: 14px;
  line-height: 21px;

  color: $colorTextPrimary;

  &.my-advisor__item-label--empty {
    color: $colorTextTetriary;
  }

  & > svg {
    margin-right: 4px;
  }
}

.my-advisor__item-value {
  font-size: 18px;
  line-height: 27px;
  font-weight: 700;
  letter-spacing: 0.5px;

  color: $colorTextPrimary;

  .my-advisor__item-content & {
    font-size: 14px;
    line-height: 21px;
    font-weight: 500;
  }
}
