@import "../../../assets/scss/variables";
@import "../../../assets/scss/mixins";

.toolbar-search {
  &__container {
    position: relative;
  }

  &__input {
    @include reset-input();
    @include body();

    padding: 7.5px 32px 7.5px 7.5px;

    background: #e4e5e9;
    border-radius: 4px;

    color: $colorTextPrimary;
    border: none;

    outline: none;

    //background-image: url(/assets/img/icon/svg/searchInput.svg);
    //background-repeat: no-repeat;
    //background-position: 97% center;
    //background-size: 17.5px 17.5px;

    &:hover,
    &:focus {
      //outline: 1px solid $colorUi;
      box-shadow: 0 0 0 1px $colorUi;
    }
  }

  &__button {
    @include reset-button();

    position: absolute;
    top: 6px;
    right: 4px;

    width: 24px;
    height: 24px;
  }
}
