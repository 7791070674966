$colorWhite: #ffffff;
$colorBrand: #6885ee;
$colorBrandSecondary: #6a68fb;
$colorUi: #6885ee;
$colorUiYellow: #ff9d0a;
$colorTextPrimary: #2c2a3f;
$colorTextSecondary: #5c6178;
$colorTextTetriary: #85859a;
$colorText3: #8b90a0;
$colorFieldLabel: #838d98;
$colorTextGreen: #4cc461;
$colorTextRed: #ff3b30;
$colorBackground: #f9f9f9;
$colorBackgroundSecondary: #f0f3fd;
$colorBackgroundYellow: #fff4e8;
$colorBackgroundDisabled: #f6f6f8;
$colorBackgroundDisabledF: #ededf1;
$colorDivider: #e8eaec;

$breakpointSmMax: 579px;
$breakpointSm: 580px;
$breakpointMdMax: 959.9px;
$breakpointMd: 960px;
$breakpointNote: 1366px;
$breakpointLg: 1440px;

$breakpointMdMaxMui: 1279px;

$breakpointGoalAddTile: 1840px;

$font: "PT Root UI", "PT Serif", sans-serif;
