@import "../../assets/scss/_variables";
@import "../../assets/scss/mixins";

.content-main--operations {
  @media (min-width: $breakpointMd) {
    & .slick-slider {
      width: 100%;
    }
  }
}
.content-main--operations .slick-track {
  padding-bottom: 120px;

  .dashboard__combine & {
    padding-bottom: 0;
  }

  &::before {
    z-index: -1;
    content: "";
    position: absolute;
    top: 69px;
    display: block;
    width: 100%;
    height: 2px;

    background-image: url(/assets/img/events/timeLineTrack.png);
    background-repeat: repeat-x;

    @media (max-width: $breakpointMd) {
      top: 69px;
    }
  }

  & div {
    outline: none;
  }

  .slick-slide {
    max-width: 100vw;
    margin-bottom: 145px;
  }
}

.dashboard__combine .slick-track .slick-slide {
  margin-bottom: 0;
}

.operations__caption-age {
  font-size: 18px;
  line-height: 30px;
  color: $colorText3;
}

.operations__slide {
  max-width: 100vw;
  margin: 16px;
  outline: none;
}

.operations__age-container {
  margin-top: 2px;

  font-size: 18px;
  line-height: 30px;
  color: $colorTextPrimary;
  text-align: center;
}

.operations__age {
  font-size: 18px;
  line-height: 30px;
  color: $colorTextPrimary;
  text-align: center;

  &::after {
    content: "";
    display: block;
    width: 12px;
    height: 12px;
    margin-top: 16px;
    margin-left: auto;
    margin-right: auto;

    background-color: #f6f6f8;
    mix-blend-mode: normal; /* Brand Color */
    border: 2px solid $colorBrand;
    box-sizing: border-box;
    border-radius: 50%;
  }
}

.operations__card-container {
  @include box-shadow-1();
  padding-top: 8px;
  padding-bottom: 8px;

  width: 256px;

  background: $colorWhite;
  border-radius: 4px;

  @media (max-width: $breakpointMdMax) {
    padding-bottom: 60px;
  }
}

.op-body {
  padding-bottom: 8px;
}

.op-body__item--low {
  @include box-shadow-2();
  background-color: $colorBackgroundSecondary;
  border-radius: 4px;

  margin-left: 8px;
  margin-right: 8px;
  margin-bottom: 8px;
  box-sizing: border-box;

  .operations__item--first + & {
    margin-top: -2px;
  }
}

.operations__item {
  &.operations__item--opened {
    @include box-shadow-2();
    margin-left: 8px;
    margin-right: 8px;
    margin-bottom: 8px;

    background: $colorWhite;
    border: 1px solid $colorDivider;
    box-sizing: border-box;
    border-radius: 4px;
  }

  &.operations__item--opened:not(.operations__item--first) {
    margin-top: -2px;
  }

  &.operations__item--always-open {
    @include box-shadow-1();
    margin-left: 8px;
    margin-right: 8px;
    border: 1px solid #e8eaec;
    box-sizing: border-box;
    border-radius: 4px;
  }

  &.operations__item--first {
    &::after {
      content: "";
      display: block;
      width: 238px;
      height: 1px;
      margin: 0 auto;

      background-color: $colorDivider;
    }
  }
}

.operations__item-head {
  padding: 10px 24px;

  display: flex;
  justify-content: flex-start;
  align-items: center;

  &.operations__item-head--top {
    padding: 16px 24px;
  }

  .operations__item:not(.operations__item--always-open) & {
    cursor: pointer;
  }

  .operations__item--opened.operations__item--opened-low-level
    &.operations__item-head--top {
    padding: 16px;
  }

  .operations__item--opened:not(.operations__item--opened-low-level)
    &.operations__item-head--top,
  .operations__item--always-open &.operations__item-head--top {
    padding-left: 16px;
    padding-right: 16px;
    border-bottom: 1px solid $colorDivider;
  }

  .op-body__item--low > & {
    cursor: default;

    padding: 12px 16px;
    border-bottom: 1px solid rgba($colorBrand, 0.2);
  }
}

.operations__item-head-icon {
  @include reset-button();

  margin-right: 10px;
}

.operations__item-head-title {
  display: flex;
  flex-direction: column;
}

.operations__item-head-total {
  font-size: 14px;
  line-height: 16px;
  font-weight: 700;

  letter-spacing: 0.5px;
  text-transform: uppercase;

  text-align: left;

  color: $colorTextPrimary;
}
.operations__item-head-label {
  font-size: 11px;
  line-height: 16px;
  font-weight: 500;
  letter-spacing: 1px;
  text-transform: uppercase;
  text-align: left;

  color: $colorTextTetriary;

  &.operations__item-head-label--clickable {
    & > span {
      display: inline-block;
      margin-bottom: 4px;
      border-bottom: 1px $colorTextTetriary dashed;
    }
  }
}

.operations__item-head-arrow {
  width: 24px;
  height: 24px;
  margin-left: auto;

  transition: 0.1s;
  transform: rotate(180deg);

  &--opened {
    padding-left: 6px;
    transition: 0.1s;
    transform: rotate(0deg);
  }
}

.operations__card-item {
  width: calc(50% - 4px);

  &--total {
    margin-bottom: 8px;
    text-align: center;

    font-size: 16px;
    line-height: 24px;
    color: $colorTextTetriary;
  }

  &--green {
    color: $colorTextGreen;
  }

  &--yellow {
    color: $colorUiYellow;
  }
}

.slick-arrow.slick-arrow {
  @media (min-width: $breakpointMd) {
    display: none !important;
  }
}

.slick-track .asset-card__wrapper {
  @media (min-width: $breakpointMd) {
    margin-left: 0;
    margin-right: 16px;
  }
}

.operations__buttons {
  @media (max-width: $breakpointMdMax) {
    top: -10px;
    width: 60px;

    font-size: 18px;
    line-height: 30px; /* identical to box height, or 167% */
    text-align: right;
    letter-spacing: 0.5px;

    color: $colorUi;

    &.slick-prev:hover,
    &.slick-prev:focus,
    &.slick-next:hover,
    &.slick-next:focus {
      color: $colorUi;
      opacity: 0.8;
    }

    &.slick-prev.slick-disabled,
    &.slick-next.slick-disabled {
      color: $colorTextTetriary;
    }

    &::before {
      display: none;
    }

    &.operations__prev-mobile {
      left: 16px;
    }

    &.operations__next-mobile {
      right: 16px;
    }
  }
}

.slider-scroll__container {
  display: flex;
  justify-content: center;
  align-items: center;

  width: 100%;

  @media (min-width: $breakpointMd) {
    margin-top: -30px;
  }
}

.slider-arrow {
  @media (max-width: $breakpointMdMax) {
    display: none;
  }

  padding: 4px;
  cursor: pointer;

  &::before {
    content: "";
    display: block;
    width: 10px;
    height: 10px;

    border-right: 2px solid $colorUi;
    border-top: 2px solid $colorUi;
  }

  &.slider-arrow--disabled {
    opacity: 0.6;
    cursor: default;
  }
}

.slider-arrow__next {
  margin-right: 20%;
  transform: rotate(45deg);
}

.slider-arrow__prev {
  margin-left: 20%;
  transform: rotate(-135deg);
}

.slider-scroll {
  @media (max-width: $breakpointMdMax) {
    display: none;
  }

  -webkit-appearance: none;
  width: 55%;
  margin: 16px;
  background-color: transparent;
}

.slider-scroll:focus {
  outline: none;
}
.slider-scroll::-webkit-slider-runnable-track {
  width: 100%;
  height: 5px;
  cursor: pointer;
  background: rgba($colorTextTetriary, 0.2);

  border-radius: 16px;
}
.slider-scroll::-webkit-slider-thumb {
  height: 18px;
  width: 180px;
  cursor: pointer;
  -webkit-appearance: none;
  margin-top: -5px;

  @media (max-width: 1250px) {
    width: 90px;
    height: 9px;
    margin-top: -2px;

    border-radius: 16px;
    background: #6885ee;
    cursor: pointer;
  }

  background-image: url("/assets/img/events/eventScrollThumb.svg");
  background-repeat: no-repeat;
  background-position: center center;
}

.slider-scroll::-moz-range-track {
  width: 100%;
  height: 5px;
  cursor: pointer;
  background: rgba($colorTextTetriary, 0.2);
  border-radius: 16px;
}
.slider-scroll::-moz-range-thumb {
  height: 18px;
  width: 180px;
  cursor: pointer;
  margin-top: -5px;

  background-image: url("/assets/img/events/eventScrollThumb.svg");
  background-repeat: no-repeat;
  background-position: center center;
  background-color: transparent;
  border: none;

  @media (max-width: 1250px) {
    width: 90px;
    height: 9px;
    margin-top: -2px;

    border-radius: 16px;
    background: #6885ee;
    cursor: pointer;
  }
}

.slider-scroll::-ms-track {
  margin-top: 24px;
  padding-bottom: 12px;
  width: 99%;
  height: 8px;

  cursor: pointer;
  background: transparent;
  border-color: transparent;
  color: transparent;
}
.slider-scroll::-ms-fill-lower {
  background: rgba($colorTextTetriary, 0.2);
  border-radius: 16px;
}
.slider-scroll::-ms-fill-upper {
  background: rgba($colorTextTetriary, 0.2);
  border-radius: 16px;
}

.slider-scroll::-ms-thumb {
  width: 90px;
  border-radius: 16px;
  background: #6885ee;
  cursor: pointer;
  height: 18px;

  @media (max-width: 1250px) {
    width: 90px;
    height: 9px;
    margin-top: -2px;

    border-radius: 16px;
    background: #6885ee;
    cursor: pointer;
  }
}

.slider-scroll:focus::-ms-fill-lower {
  background: rgba($colorTextTetriary, 0.2);
}
.slider-scroll:focus::-ms-fill-upper {
  background: rgba($colorTextTetriary, 0.2);
}
