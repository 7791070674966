@import "../../assets/scss/_variables";
@import "../../assets/scss/mixins";

.hhcard {
  @include box-shadow-1();

  width: 360px;
  margin-right: 16px;
  margin-bottom: 8px;

  background: $colorWhite;
  border-radius: 4px;

  .household__forms--profile & {
    margin-bottom: 16px;
  }

  @media (max-width: 1400px) {
    width: 340px;
  }

  @media (max-width: 1200px) {
    width: 320px;
  }

  @media (max-width: $breakpointMdMax) {
    margin-bottom: 16px;
    width: calc(100vw - 32px);
    max-width: calc(100% - 32px);
    margin-left: 16px;
    margin-right: 16px;
  }

  &.hhcard--full-width {
    width: 100%;
    margin: 0;
  }
}

.hhcard__title {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  padding: 16px;

  font-size: 14px;
  line-height: 16px;
  letter-spacing: 0.5px;
  text-transform: uppercase;

  color: $colorTextSecondary;

  border-bottom: 1px solid $colorDivider;
}

.hhcard__title-icon {
  position: relative;

  width: 32px;
  height: 32px;

  &::before {
    position: absolute;
    display: block;
    width: 32px;
    height: 32px;
    content: "";

    background: $colorBrand;
    opacity: 0.1;
    border-radius: 10px;
  }

  & img {
    margin: 8px;
    width: 16px;
    height: 16px;
  }
}

.hhcard__title-text {
  width: 100%;

  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;

  margin-left: 10px;

  font-size: 14px;
  line-height: 16px;
  letter-spacing: 0.5px;
  text-transform: uppercase;

  color: $colorTextSecondary;

  & .hhcard__title-subtitle {
    font-size: 11px;
    line-height: 16px;
    color: $colorTextTetriary;
  }
}

.hhcard__top-action {
  margin-left: auto;
}

.hhcard__body {
  padding: 24px 24px 48px 24px;

  &--no-padding {
    padding: 0;
  }

  .hhcard--full-width &:not(.hhcard__body--no-padding) {
    padding-bottom: 24px;
  }
}
